import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const ChartHint = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

ChartHint.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]).isRequired,
};

export default ChartHint;
