import { createMuiTheme } from '@material-ui/core/styles';

import { hexToRgb } from '../helpers/style';
import SEGOE_UI from '../assets/Segoe.woff2';

const MAIN_COLOR = '#02836B';
const MAIN_COLOR_FOCUS = '#006250';
const BODY_COLOR = 'rgba(0, 0, 0, 0.87)';

const segoe = {
  fontFamily: 'Segoe UI',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Segoe UI'),
    url(${SEGOE_UI}) format('woff2')
  `,
};

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [segoe],
        body: {
          padding: 0,
          margin: 0,
          fontFamily: 'Segoe UI, Regular',
        },
        '.rbt-token': {
          color: BODY_COLOR,
          backgroundColor: `rgba(${hexToRgb(MAIN_COLOR_FOCUS, { prefix: false })}, 0.2)`,
        },
        'ul.thumbs': {
          overflowX: 'auto',
        },
        'div#root': {
          height: '100vh',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        'input.form-control': {
          '&:focus': {
            borderColor: MAIN_COLOR_FOCUS,
            boxShadow: `0 0 0 0.2rem rgba(${hexToRgb(MAIN_COLOR_FOCUS, { prefix: false })}, 0.5)`,
          },
        },
        '.form-control.focus': {
          borderColor: MAIN_COLOR_FOCUS,
          boxShadow: `0 0 0 0.2rem rgba(${hexToRgb(MAIN_COLOR_FOCUS, { prefix: false })}, 0.5)`,
        },
        '.dropdown-item.active, .dropdown-item:active': {
          backgroundColor: MAIN_COLOR,
        },
        '.btn.btn-primary': {
          backgroundColor: MAIN_COLOR,
          borderColor: MAIN_COLOR,
          '&:hover': {
            backgroundColor: MAIN_COLOR_FOCUS,
            borderColor: MAIN_COLOR_FOCUS,
          },
          '&:focus': {
            backgroundColor: MAIN_COLOR_FOCUS,
            borderColor: MAIN_COLOR_FOCUS,
            boxShadow: `0 0 0 0.2rem rgba(${hexToRgb(MAIN_COLOR_FOCUS, { prefix: false })}, 0.5) !important`,
          },
          '&:active': {
            backgroundColor: `${MAIN_COLOR_FOCUS} !important`,
            borderColor: `${MAIN_COLOR_FOCUS} !important`,
            boxShadow: `0 0 0 0.2rem rgba(${hexToRgb(MAIN_COLOR_FOCUS, { prefix: false })}, 0.5)`,
          },
        },
        '.dot.selected': {
          backgroundColor: `${MAIN_COLOR} !important`,
        },
        '.carousel .slide': {
          backgroundColor: '#FFFFFF !important',
        },
      },
    },
  },
  palette: {
    secondary: {
      main: MAIN_COLOR,
    },
    geoJson: {
      main: MAIN_COLOR,
      secondary: '#F0CC2B',
      harvest: {
        '2018/2019': MAIN_COLOR,
        '2019/2020': '#021a83',
        '2020/2021': '#0f8302',
      },
    },
    table: {
      head: '#DEDEDE',
      background1: '#F3F2F2',
      background2: '#FFFFFF',
    },
  },
  typography: {
    link: {
      color: MAIN_COLOR,
      '&:hover': {
        color: MAIN_COLOR_FOCUS,
      },
    },
    body: {
      color: BODY_COLOR,
      '&:hover': {
        color: BODY_COLOR,
        textDecoration: 'none',
      },
    },
  },
});

export default theme;
