import React from 'react';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '../Button';
import Icon from '../Icon';
import useStyles from './styles';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const ExcelExport = ({ data, columns, sheetName }) => {
  const classes = useStyles();

  return (
    <ExcelFile
      filename={`${sheetName}_${moment().format('YYYYMMDDHHmmss')}`}
      element={(
        <Button>
          <div className={classes.buttonContentContainer}>
            <Icon
              name="faFileExcel"
              size="1x"
              color="white"
            />
            Exportar
          </div>
        </Button>
        )}
    >
      <ExcelSheet
        dataSet={[{
          columns,
          data,
        }]}
        name={sheetName}
      />
    </ExcelFile>
  );
};

ExcelExport.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.oneOf([null]),
    ])),
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  sheetName: PropTypes.string.isRequired,
};

export default ExcelExport;
