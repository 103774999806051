import { makeStyles } from '@material-ui/styles';

import background from '../../../../assets/silo.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 2,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  container: {
    height: '100%',
    width: '100%',
    background: 'transparent linear-gradient(180deg, #02836B 0%, #05685699 100%) 0% 0% no-repeat padding-box',
    opacity: '0.3',
  },
});

export default useStyles;
