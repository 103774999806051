import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
    marginBottom: 0,
  },
});

export default useStyles;
