import { makeStyles } from '@material-ui/styles';
import { hexToRgb } from '../../helpers/style';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 35,
    width: 190,
    backgroundColor: 'white',
    borderColor: '#7070704D',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
  },
  optionContainer: {
    height: 220,
    width: 400,
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: '#D4D4D4',
    borderWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  option: {
    textTransform: 'uppercase',
    width: '50%',
    padding: '0px 10px',
    height: 'calc(100% / 6)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(theme.palette.secondary.main, { prefix: false })}, 0.2)`,
    },
  },
  optionSelected: {
    backgroundColor: `rgba(${hexToRgb(theme.palette.secondary.main, { prefix: false })}, 0.2)`,
  },
  displayNone: {
    display: 'none',
  },
}));

export default useStyles;
