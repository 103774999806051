import React, { useCallback } from 'react';
import { Pagination as MuiPagination } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { ITEMS_PER_PAGE } from '../../constants';

const Pagination = ({
  total, perPage, page, onChange,
}) => {
  const handleChange = useCallback((_, p) => {
    onChange(p);
  }, [onChange]);

  return (
    <MuiPagination
      count={Math.ceil(total / perPage)}
      page={page}
      onChange={handleChange}
      color="secondary"
    />
  );
};

Pagination.defaultProps = {
  perPage: ITEMS_PER_PAGE,
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
