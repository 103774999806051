import React from 'react';

import { Banner, Form } from './components';
import useStyles from './styles';

const Login = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Form />
      <Banner />
    </div>
  );
};

export default Login;
