import React, { useEffect, useState } from 'react';
import {
  Circle, Polygon, Polyline,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';

import { get } from 'lodash';
import { getPolygonsFence, handleGeoJsonToShape } from '../../helpers/map';
import InfoWindow from '../InfoWindow';

const GeoJson = ({ data, options, children }) => {
  const theme = useTheme();
  const [showFarmInfo, setShowFarmInfo] = useState(false);
  const [fenceData, setFenceData] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [lines, setLines] = useState([]);

  const handlePolygonClick = () => {
    setShowFarmInfo(true);
  };

  const handleCloseInfoWindow = () => {
    setShowFarmInfo(null);
  };

  useEffect(() => {
    if (polygons.length) {
      const { center, radius } = getPolygonsFence(polygons);

      setFenceData({
        center,
        radius,
      });
    }
  }, [polygons]);

  useEffect(() => {
    const { polygons: polygonsToRender, lines: linesToRender } = handleGeoJsonToShape(data);

    setPolygons(polygonsToRender);
    setLines(linesToRender);
  }, [data]);

  return (
    <>
      {
        polygons.map((polygon, index) => (
          <Polygon
            key={index.toString()}
            paths={polygon}
            options={{
              fillColor: theme.palette.secondary.main,
              fillOpacity: 0.4,
              strokeColor: theme.palette.secondary.main,
              strokeOpacity: 1,
              strokeWeight: 1,
              ...options,
            }}
            onClick={handlePolygonClick}
          />
        ))
    }
      {
        lines.map((line, index) => (
          <Polyline
            key={index.toString()}
            path={line}
            options={{
              fillColor: theme.palette.secondary.main,
              fillOpacity: 0.4,
              strokeColor: theme.palette.secondary.main,
              strokeOpacity: 1,
              strokeWeight: 1,
              ...options,
            }}
            onClick={handlePolygonClick}
          />
        ))
    }
      {
      fenceData && showFarmInfo && (
        <Circle
          center={get(fenceData, 'center', { lat: 0, lng: 0 })}
          radius={get(fenceData, 'radius', 0)}
          visible={showFarmInfo}
          options={{
            fillColor: theme.palette.secondary.main,
            fillOpacity: 0.2,
            strokeColor: theme.palette.secondary.main,
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      )
    }
      {fenceData && showFarmInfo && (
      <InfoWindow
        position={get(fenceData, 'center', { lat: 0, lng: 0 })}
        onCloseClick={handleCloseInfoWindow}
      >
        {children}
      </InfoWindow>
      )}
    </>
  );
};

GeoJson.defaultProps = {
  options: {},
};

GeoJson.propTypes = {
  data: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

export default GeoJson;
