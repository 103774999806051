import {
  DISTRICT_REFERENCE, FARM_REFERENCE, OWNER_REFERENCE, REGION_REFERENCE,
} from '../../../../constants';

export const VISION_PARAMETERS = {
  [REGION_REFERENCE]: {
    PARAM_TO_GROUP_BY: 'district.name',
  },
  [DISTRICT_REFERENCE]: {
    PARAM_TO_GROUP_BY: 'owner.name',
  },
  [OWNER_REFERENCE]: {
    PARAM_TO_GROUP_BY: 'farm.name',
  },
  [FARM_REFERENCE]: {
    PARAM_TO_GROUP_BY: 'farm.name',
  },
};
