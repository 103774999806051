import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import useStyles from './styles';
import { INPUT } from '../../styles';

const CircleButton = ({ radius, children }) => {
  const classes = useStyles({ radius });

  return (
    <Button
      variant="contained"
      color="secondary"
      classes={classes}
    >
      {children}
    </Button>
  );
};

CircleButton.defaultProps = {
  radius: INPUT.HEIGHT,
};

CircleButton.propTypes = {
  radius: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CircleButton;
