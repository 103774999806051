import React from 'react';
import PropTypes from 'prop-types';

import { Section } from '../../../../components';
import useStyles from './styles';

const GraphSection = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Section className={classes.section}>
        <div className={classes.graphTitleContainer}>
          <span className={classes.graphTitle}>
            {title}
          </span>
        </div>
        {children}
      </Section>
    </div>

  );
};

GraphSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default GraphSection;
