import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    padding: '5px 5px',
    height: 340,
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  graphTitleContainer: {
    padding: 20,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  graphTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
});

export default useStyles;
