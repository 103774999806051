import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const Section = ({ children, className }) => (
  <Paper
    elevation={3}
    className={className}
  >
    {children}
  </Paper>
);

Section.defaultProps = {
  className: '',
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Section;
