import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  mapContainer: {
    display: 'flex',
    flex: 1,
    padding: '15px 25px',
  },
  resultContainer: {
    display: 'flex',
    flex: 3,
    marginBottom: 15,
  },
  container: {
    display: 'flex',
    height: 'calc(100vh - 80px - 55px)',
  },
  displayNone: {
    display: 'none',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterToStartLabel: {
    fontWeight: 'bold',
  },
});

export default useStyles;
