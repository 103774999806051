import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/styles';
import { get } from 'lodash';
import { FarmInfoWindow, Map } from '../../../../components';
import useStyles from './styles';
import { format } from '../../../../helpers/format';
import { divide, ruleOfThree, sum } from '../../../../helpers/math';

const HomeMap = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [geoJsonsToRender, setGeoJsonsToRender] = useState([]);

  const handleGeoJsons = useCallback((visits) => {
    const handledGeoJsons = visits.reduce((previous, visit) => {
      const renderItem = (
        <FarmInfoWindow
          regionId={visit.region.id}
          districtId={visit.district.id}
          ownerId={visit.owner.id}
          farmId={visit.farm.id}
          ownerName={visit.owner.name}
          farmName={visit.farm.name}
          cityState={`${visit.city.name}/${visit.state.name}`}
          informedArea={`${format(
            sum(visit.informedArea.conventional, visit.informedArea.ipro),
          )} ha`}
          auditedArea={`${format(
            sum(visit.area.conventional, visit.area.ipro),
          )} ha`}
          iproPenetration={`${format(
            ruleOfThree(
              sum(visit.area.conventional, visit.area.ipro),
              100,
              visit.area.ipro,
            ),
          )}%`}
        />
      );

      return [
        ...previous,
        {
          renderItem,
          options: {
            fillColor: get(
              theme.palette.geoJson.harvest,
              visit.harvest.name,
              theme.palette.geoJson.main,
            ),
            fillOpacity: 0.4,
            strokeColor: get(
              theme.palette.geoJson.harvest,
              visit.harvest.name,
              theme.palette.geoJson.main,
            ),
            strokeOpacity: 1,
            strokeWeight: 1,
          },
          geoJson: visit.kuhlmannGeoJson,
          legend: {
            color: get(
              theme.palette.geoJson.harvest,
              visit.harvest.name,
              theme.palette.geoJson.main,
            ),
            label: visit.harvest.name,
          },
        }, {
          legend: {
            color: theme.palette.geoJson.secondary,
            label: 'CAR',
          },
          renderItem,
          options: {
            fillOpacity: 0,
            strokeColor: theme.palette.geoJson.secondary,
            strokeOpacity: 1,
            strokeWeight: 2,
          },
          geoJson: visit.carGeoJson,
        },
      ];
    }, []).filter(({ geoJson }) => geoJson);

    setGeoJsonsToRender(handledGeoJsons);
  }, [theme.palette.geoJson.main, theme.palette.geoJson.harvest, theme.palette.geoJson.secondary]);

  useEffect(() => {
    handleGeoJsons(data);
  }, [data, handleGeoJsons]);

  return (
    <div className={classes.container}>
      <Map
        style={{
          borderRadius: 8,
        }}
        initialCenter={{
          lat: -13.5409218,
          lng: -58.1019205,
        }}
        zoom={10.25}
        pin={data.reduce((previous, current) => {
          const silos = get(current, 'silos', []).map((silo) => ({
            coords: {
              lat: silo.latitude,
              lng: silo.longitude,
            },
            data: [
              {
                title: 'Latitude',
                value: silo.latitude,
              },
              {
                title: 'Longitude',
                value: silo.longitude,
              },
              {
                title: 'Capacidade Estática',
                value: `${format(divide(silo.volume, 1000))} T`,
              },
              {
                title: 'Fazenda',
                value: silo.farm.name,
              },
              {
                title: 'Produtor',
                value: silo.owner.name,
              },
            ],
          }));

          return [
            ...previous,
            ...silos,
          ];
        }, [])}
        geoJson={geoJsonsToRender}
      />
    </div>
  );
};

HomeMap.defaultProps = {
  data: [],
};

HomeMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default HomeMap;
