import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  buttonContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& div': {
      marginRight: 5,
    },
  },
});

export default useStyles;
