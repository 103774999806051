import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 3,
    margin: '15px 0px',
    flexDirection: 'column',
    paddingLeft: 15,
    overflowY: 'auto',
  },
  paginationContainer: {
    display: 'flex',
    padding: '15px 0px',
    justifyContent: 'center',
  },
  displayNone: {
    display: 'none',
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  emptyText: {
    fontWeight: 'bold',
  },
});

export default useStyles;
