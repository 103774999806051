import { range } from 'lodash';
import { AXIS_DIVISIONS } from '../constants';
import { ceil, divide, sum } from './math';

export const handleAxisDivision = (maxValue = 0, steps = AXIS_DIVISIONS) => {
  const value = ceil(maxValue);
  const division = divide(value, steps);

  return range(0, sum(value, division), division);
};

export const messages = {
  EMPTY_DATA: 'Não há dados para exibição.',
};
