import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { get, uniqueId } from 'lodash';
import classnames from 'classnames';

import useStyles from './styles';

const ButtonSelect = ({
  label, data, value, onChange, onRootDoubleClick,
}) => {
  const classes = useStyles();
  const buttonRef = useRef();
  const idButton = uniqueId('select_button_');

  const [isPanelVisible, setIsPanelVisible] = useState(false);

  const handleButtonClick = useCallback(() => {
    setIsPanelVisible(!isPanelVisible);
  }, [isPanelVisible]);

  const optionClicked = useCallback((item) => {
    setIsPanelVisible(false);
    onChange(item);
  }, [onChange]);

  const handleWindowClick = useCallback(({ target }) => {
    const clickedElementId = get(target, 'id', null);

    if (clickedElementId !== buttonRef.current.id) {
      setIsPanelVisible(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [handleWindowClick]);

  return (
    <div>
      <button
        ref={buttonRef}
        type="button"
        className={classes.root}
        onClick={handleButtonClick}
        onDoubleClick={onRootDoubleClick}
        id={idButton}
      >
        {label}
      </button>
      <div
        className={classnames({
          [classes.optionContainer]: true,
          [classes.displayNone]: !isPanelVisible,
        })}
      >
        {
          data
            .map((item, index) => (
              <div
                key={index.toString()}
                className={classnames({
                  [classes.option]: true,
                  [classes.optionSelected]: item.id === value,
                })}
                onClick={() => optionClicked(item)}
              >
                <span>{item.label}</span>
              </div>
            ))
        }
      </div>
    </div>
  );
};

ButtonSelect.defaultProps = {
  value: [],
  onChange: () => {},
  onRootDoubleClick: () => {},
};

ButtonSelect.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })),
  onRootDoubleClick: PropTypes.func,
};

export default ButtonSelect;
