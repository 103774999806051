import { ActionTypes } from '.';

export const storeUser = ({
  id, name, email, login,
}) => ({
  type: ActionTypes.USER_STORE_USER,
  payload: {
    id, name, email, login,
  },
});

export const clearUser = () => ({
  type: ActionTypes.USER_CLEAR_USER,
  payload: {},
});
