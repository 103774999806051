import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '5px 25px',
  },
  inputContainer: {
    display: 'flex',
    flex: 2,
    padding: '0px 5px',
    flexDirection: 'column',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 0px',
  },
  searchContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  visionOptionContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default useStyles;
