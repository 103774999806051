const ActionTypes = {
  AUTH_STORE_TOKEN: 'AUTH_STORE_TOKEN',
  AUTH_CLEAR_TOKEN: 'AUTH_CLEAR_TOKEN',
  USER_STORE_USER: 'USER_STORE_USER',
  USER_CLEAR_USER: 'USER_CLEAR_USER',
  KML_STORE_KMLS: 'KML_STORE_KMLS',
  KML_CLEAR_KMLS: 'KML_CLEAR_KMLS',
};

export {
  ActionTypes,
};
