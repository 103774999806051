import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import useStyles from './styles';
import {
  Loading, Icon, Modal, Carousel, Menu,
} from '../../../../components';
import { Koogle } from '../../../../services';
import {
  DISTRICT_REFERENCE, FARM_REFERENCE, OWNER_REFERENCE, REGION_REFERENCE,
} from '../../../../constants';

const Card = ({
  image, farm, owner, city, district, region,
}) => {
  const classes = useStyles({
    image: get(image, 'base64', null),
    isLoadingImage: get(image, 'isLoading', false),
  });

  const [showHoverImage, setShowHoverImage] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [isLoadingFarmImages, setIsLoadingFarmImages] = useState(false);
  const [farmImages, setFarmImages] = useState([]);

  const handleMouseEnter = useCallback(() => {
    setShowHoverImage(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setShowHoverImage(false);
  }, []);

  const handleOpenPhotosModal = useCallback(async () => {
    setShowImagesModal(true);

    if (!farmImages.length) {
      setIsLoadingFarmImages(true);
      const resultFarmImages = await Koogle.getFarmsPhotos(farm.id);

      setFarmImages(resultFarmImages);
      setIsLoadingFarmImages(false);
    }
  }, [farm.id, farmImages]);

  const handleClosePhotosModal = useCallback(() => {
    setShowImagesModal(false);
  }, []);

  const baseSearchParams = {
    region: region.id,
    district: district.id,
    owner: owner.id,
    farm: farm.id,
  };

  return (
    <>
      <div className={classes.container}>
        <div
          className={classes.imageContainer}
        >
          {
            get(image, 'isLoading', false)
              ? <Loading />
              : (
                <div
                  className={classes.imageContainerHover}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseOut}
                  onClick={handleOpenPhotosModal}
                >
                  {showHoverImage && (
                  <Icon
                    name="faSearchPlus"
                    size="2x"
                    color="white"
                  />
                  )}
                </div>
              )
          }
        </div>
        <div
          className={classes.dataContainer}
        >
          <div
            className={classes.dataRow}
          >
            <div className={classes.cardColumn}>
              <div className={classes.infoContainer}>
                <span className={classes.label}>Fazenda:</span>
                <span title={get(farm, 'name', '')} className={classes.data}>{get(farm, 'name', '')}</span>
              </div>
              <div className={classes.infoContainer}>
                <span className={classes.label}>Produtor:</span>
                <span title={get(owner, 'name', '')} className={classes.data}>{get(owner, 'name', '')}</span>
              </div>
              <div className={classes.infoContainer}>
                <span className={classes.label}>Cultura:</span>
                <span title="SOJA" className={classes.data}>SOJA</span>
              </div>
            </div>
            <div className={classes.cardColumn}>
              <div className={classes.infoContainer}>
                <span className={classes.label}>Cidade:</span>
                <span title={get(city, 'name', '')} className={classes.data}>{get(city, 'name', '')}</span>
              </div>
              <div className={classes.infoContainer}>
                <span className={classes.label}>CPF/CNPJ:</span>
                <span title={get(owner, 'identity', '')} className={classes.data}>{get(owner, 'identity', '')}</span>
              </div>
              <div className={classes.infoContainer}>
                <span className={classes.label}>Regional/Distrito:</span>
                <span title={`${get(region, 'name', '')}/${get(district, 'name', '')}`} className={classes.data}>{`${get(region, 'name', '')}/${get(district, 'name', '')}`}</span>
              </div>
            </div>
          </div>

          <div className={classes.dashboardLink}>
            <Menu
              options={[
                {
                  value: 'Regional',
                  to: {
                    route: 'dashboard',
                    search: {
                      ...baseSearchParams,
                      vision: REGION_REFERENCE,
                    },
                  },
                }, {
                  value: 'Distrito',
                  to: {
                    route: 'dashboard',
                    search: {
                      ...baseSearchParams,
                      vision: DISTRICT_REFERENCE,
                    },
                  },
                }, {
                  value: 'Produtor',
                  to: {
                    route: 'dashboard',
                    search: {
                      ...baseSearchParams,
                      vision: OWNER_REFERENCE,
                    },
                  },
                }, {
                  value: 'Fazenda',
                  to: {
                    route: 'dashboard',
                    search: {
                      ...baseSearchParams,
                      vision: FARM_REFERENCE,
                    },
                  },
                },
              ]}
            >
              <div className={classes.menuContainer}>
                <span className={classes.dashboardSpan}>
                  Dashboard
                </span>
                <Icon
                  name="faArrowRight"
                />
              </div>
            </Menu>

          </div>
        </div>
      </div>
      <Modal
        title={farm.name}
        isVisible={showImagesModal}
        onClose={handleClosePhotosModal}
        body={
          isLoadingFarmImages
            ? (
              <div className={classes.loadingFarmImageContainer}>
                <Loading />
              </div>
            ) : (
              <Carousel
                items={farmImages.map((imageToShow) => ({
                  image: imageToShow,
                }))}
              />
            )
        }
      />
    </>
  );
};

Card.defaultProps = {
  image: {
    isLoading: true,
    encoded: null,
  },
  farm: {
    name: '-',
  },
  owner: {
    name: '-',
  },
  city: {
    name: '-',
  },
  region: {
    name: '-',
  },
  district: {
    name: '-',
  },
};

Card.propTypes = {
  image: PropTypes.shape({
    isLoading: PropTypes.bool,
    encoded: PropTypes.string,
  }),
  farm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  owner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  city: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  region: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  district: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default Card;
