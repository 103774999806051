import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import useStyles from './styles';

const Input = ({ label, placeholder, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      {label && <Form.Label className={classes.label}>{label}</Form.Label> }
      <Form.Control placeholder={placeholder} {...rest} />
    </>
  );
};

Input.defaultProps = {
  placeholder: '',
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default Input;
