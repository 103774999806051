const routes = {
  login: {
    path: '/',
    component: 'Login',
    private: false,
    showBackButton: false,
  },
  home: {
    path: '/home',
    component: 'Home',
    private: true,
    showBackButton: false,
  },
  farm: {
    path: '/fazenda/:id',
    component: 'Farm',
    private: true,
    showBackButton: true,
  },
  dashboard: {
    path: '/dashboard',
    component: 'Dashboard',
    private: true,
    showBackButton: true,
  },
};

export default routes;
