import Decimal from 'decimal.js';
import { isNaN } from 'lodash';

const { PI } = Math;

const Calculator = (parameter) => new Decimal(parameter);

export const degToRad = (deg) => deg * (PI / 180);

export const isEven = (n) => n % 2 === 0;

export const multiply = (...values) => {
  let calc = Calculator(1);

  values.forEach((value) => {
    calc = calc.times(parseFloat(value));
  });

  return Number(calc);
};

export const sum = (...values) => {
  let calc = Calculator(0);

  values.forEach((value) => {
    calc = calc.plus(parseFloat(value));
  });

  return Number(calc);
};

export const divide = (dividend = 0, divisor = 1) => {
  let calc = Calculator(parseFloat(dividend));
  calc = calc.dividedBy(parseFloat(divisor));

  return !isNaN(Number(calc)) ? Number(calc) : 0;
};

export const ceil = (value = 0) => {
  let calc = Calculator(parseFloat(value));

  calc = calc.ceil();

  return Number(calc);
};

export const toPrecision = (value = 0, precision = 2) => {
  const valueToSetPrecision = Calculator(value);

  return valueToSetPrecision.toFixed(precision);
};

export const ruleOfThree = (xMax = 1, yMax = 0, x = 0) => divide(multiply(x, yMax), xMax);
