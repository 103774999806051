import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  emptyContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
});

export default useStyles;
