import React from 'react';
import { useFormik } from 'formik';

import useStyles from './styles';
import logoBV from '../../../../assets/Logo_BV_Cinza.svg';
import logoKTMA from '../../../../assets/LOGO_KTMA_Cinza.svg';
import { Button, Input } from '../../../../components';
import { Auth } from '../../../../services';

const Form = () => {
  const classes = useStyles();

  const initialValues = {
    login: '',
    password: '',
  };

  const {
    values, errors, touched, handleSubmit, handleChange,
  } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      await Auth.login(formValues);
    },
  });

  return (
    <div className={classes.root}>
      <div className={classes.topContainer}>
        <div className={classes.koogleLogoContainer} />
        <form onSubmit={handleSubmit} className={classes.formContainer}>
          <Input
            label="Usuário"
            name="login"
            error={touched.login && errors.login}
            value={values.login}
            onChange={handleChange}
          />
          <div className={classes.space} />
          <Input
            label="Senha"
            type="password"
            name="password"
            error={touched.password && errors.password}
            value={values.password}
            onChange={handleChange}
          />
          <div className={classes.space} />
          <Button
            type="submit"
          >
            Acessar
          </Button>
        </form>
      </div>
      <div className={classes.logoContainer}>
        <div className={classes.logoFooterContainer}>
          <img src={logoKTMA} alt="KTMA Logo" />
          <div className={classes.bar} />
          <img src={logoBV} alt="BV Logo" />
        </div>
      </div>
    </div>
  );
};

export default Form;
