import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    borderRadius: 4,
    padding: '7px 10px',
    fontSize: 12,
    background: '#3a3a48',
    boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
    color: '#fff',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
});

export default useStyles;
