import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  graphsContainer: {
    padding: '30px 15px 0px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  flex1: {
    display: 'flex',
    flex: 1,
  },
  flex2: {
    display: 'flex',
    flex: 2,
  },
  flex3: {
    display: 'flex',
    flex: 3,
  },
  flex4: {
    display: 'flex',
    flex: 4,
  },
  flex5: {
    display: 'flex',
    flex: 5,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionContainer: {
    display: 'flex',
    padding: '5px 5px',
    height: 340,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  graphSubTitle: {
    padding: '20px 20px 10px',
    fontStyle: 'italic',
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 15,
    flex: 1,
    padding: 20,
  },
  graphTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  graphTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  table: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 15,
  },
  tableContainer: {
    display: 'flex',
    overflowX: 'auto',
    minWidth: '100%',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  graphHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '0px 15px',
    paddingBottom: 15,
  },
  graphHeader: {
    display: 'flex',
    height: 10,
    width: '100%',
    borderTop: '1px solid #C7C7C7',
    borderRight: '1px solid #C7C7C7',
    borderLeft: '1px solid #C7C7C7',
  },
  background1: {
    backgroundColor: '#F3F2F2',
  },
  background2: {
    backgroundColor: '#FFFFFF',
  },
  border1: {
    border: '1px solid #DEDEDE',
  },
  subRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 15px',
  },
  background3: {
    backgroundColor: '#DEDEDE',
  },
  bold: {
    fontWeight: 'bold',
  },
  sectionContent: {
    padding: 20,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoSpan: {
    display: 'flex',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoKey: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  infoValue: {
    fontWeight: 'normal',
  },
});

export default useStyles;
