import { makeStyles } from '@material-ui/styles';

import background from '../../assets/silos-oestatistico.jpg';

const useStyles = makeStyles({
  root: {
    height: 80,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    backgroundPositionY: -460,
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 2vw',
    height: '100%',
    width: '100%',
    background: 'transparent linear-gradient(180deg, #02836B 0%, #05685699 100%) 0% 0% no-repeat padding-box',
  },
  image: {
    margin: '0 20px',
  },
  bar: {
    backgroundColor: 'white',
    width: 1,
    height: '20%',
    display: 'flex',
  },
  harvestImage: {
    height: 57,
  },
  BVImage: {
    height: 64,
  },
  logoContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
});

export default useStyles;
