import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Carousel from '../Carousel';
import useStyles from './styles';
import { Koogle } from '../../services';
import LoadingWrapper from '../LoadingWrapper';
import Modal from '../Modal';
import {
  DISTRICT_REFERENCE, FARM_REFERENCE, OWNER_REFERENCE, REGION_REFERENCE,
} from '../../constants';
import Icon from '../Icon';
import Menu from '../Menu';

const FarmInfoWindow = ({
  farmId,
  ownerName,
  farmName,
  cityState,
  informedArea,
  auditedArea,
  iproPenetration,
  districtId,
  regionId,
  ownerId,
}) => {
  const classes = useStyles();

  const [isLoadingPhoto, setIsLoadingPhoto] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [isLoadingReport, setIsLoadingReport] = useState(true);
  const [reportLink, setReportLink] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const loadFarmPhotos = useCallback(async (id) => {
    const resultPhotos = await Koogle.getFarmsPhotos([id]);

    setPhotos(resultPhotos);

    setIsLoadingPhoto(false);
  }, []);

  const loadFarmReport = useCallback(async (id) => {
    const { request } = Koogle.getFarmReport(id);

    const resultReport = await request;

    setReportLink(resultReport);
    setIsLoadingReport(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  useEffect(() => {
    loadFarmPhotos(farmId);
    loadFarmReport(farmId);
  }, [farmId, loadFarmPhotos, loadFarmReport]);

  const baseSearchParams = {
    farm: farmId,
    district: districtId,
    region: regionId,
    owner: ownerId,
  };

  return (
    <>
      <div>
        <div className={classes.imageContainer}>
          <LoadingWrapper isLoading={isLoadingPhoto}>
            <Carousel
              width={250}
              items={photos.map((photo) => ({
                image: photo,
              }))}
              onClickItem={handleOpenModal}
            />
          </LoadingWrapper>
        </div>
        <div className={classes.reportContainer}>
          {
            isLoadingReport ? (
              <span>
                Carregando laudo...
              </span>
            ) : (
              <a href={reportLink} download={`${farmName}.pdf`}>{reportLink ? 'Abrir Laudo' : ''}</a>
            )
          }
        </div>
        <div>
          <Menu
            options={[
              {
                value: 'Regional',
                to: {
                  route: 'dashboard',
                  search: {
                    ...baseSearchParams,
                    vision: REGION_REFERENCE,
                  },
                },
              }, {
                value: 'Distrito',
                to: {
                  route: 'dashboard',
                  search: {
                    ...baseSearchParams,
                    vision: DISTRICT_REFERENCE,
                  },
                },
              }, {
                value: 'Produtor',
                to: {
                  route: 'dashboard',
                  search: {
                    ...baseSearchParams,
                    vision: OWNER_REFERENCE,
                  },
                },
              }, {
                value: 'Fazenda',
                to: {
                  route: 'dashboard',
                  search: {
                    ...baseSearchParams,
                    vision: FARM_REFERENCE,
                  },
                },
              },
            ]}
          >
            <div className={classes.menuContainer}>
              <span className={classes.dashboardSpan}>
                Dashboard
              </span>
              <Icon
                name="faArrowRight"
              />
            </div>
          </Menu>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.columnContainer}>
            <div className={classes.itemContainer}>
              <div>
                Produtor:
              </div>
              <div className={classes.valueText}>
                {ownerName}
              </div>
            </div>
            <div className={classes.itemContainer}>
              <div>
                Cidade/UF:
              </div>
              <div className={classes.valueText}>
                {cityState}
              </div>
            </div>
            <div className={classes.itemContainer}>
              <div>
                Área Auditada:
              </div>
              <div className={classes.valueText}>
                {auditedArea}
              </div>
            </div>
          </div>
          <div className={classes.columnContainer}>
            <div className={classes.itemContainer}>
              <div>
                Fazenda:
              </div>
              <div className={classes.valueText}>
                {farmName}
              </div>
            </div>
            <div className={classes.itemContainer}>
              <div>
                Área Informada:
              </div>
              <div className={classes.valueText}>
                {informedArea}
              </div>
            </div>
            <div className={classes.itemContainer}>
              <div>
                Penetração IPRO:
              </div>
              <div className={classes.valueText}>
                {iproPenetration}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={farmName}
        onClose={handleCloseModal}
        isVisible={isModalVisible}
        body={(
          <Carousel
            items={photos.map((photo) => ({
              image: photo,
            }))}
            onClickItem={handleOpenModal}
          />
    )}
      />
    </>
  );
};

FarmInfoWindow.propTypes = {
  farmId: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  farmName: PropTypes.string.isRequired,
  cityState: PropTypes.string.isRequired,
  informedArea: PropTypes.string.isRequired,
  auditedArea: PropTypes.string.isRequired,
  iproPenetration: PropTypes.string.isRequired,
  districtId: PropTypes.string.isRequired,
  regionId: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
};

export default FarmInfoWindow;
