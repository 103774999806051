import { ActionTypes } from '.';

export const storeKmls = (kmls = []) => ({
  type: ActionTypes.KML_STORE_KMLS,
  payload: {
    kmls,
  },
});

export const clearKmls = () => ({
  type: ActionTypes.KML_CLEAR_KMLS,
  payload: {},
});
