import React, { useCallback, useState } from 'react';
import { Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';

import InfoWindow from '../InfoWindow';
import useStyles from './styles';

const MapPin = ({ coords, data }) => {
  const [isInfoWindowVisible, setIsInfoWindowVisible] = useState(false);
  const classes = useStyles();

  const handleToggleInfoWindowVisibility = useCallback(() => {
    setIsInfoWindowVisible((actualVisibility) => !actualVisibility);
  }, []);

  return (
    <>
      <Marker
        position={{
          lat: coords.lat,
          lng: coords.lng,
        }}
        onClick={handleToggleInfoWindowVisibility}
      />
      {isInfoWindowVisible && (
      <InfoWindow
        position={{
          lat: coords.lat,
          lng: coords.lng,
        }}
        onCloseClick={handleToggleInfoWindowVisibility}
      >
        <div className={classes.infoContainer}>
          {
            data.map(({ title, value }) => (
              <div>
                {title}
                :
                <span className={classes.valueText}>{value}</span>
              </div>
            ))
        }
        </div>
      </InfoWindow>
      )}
    </>
  );
};

MapPin.defaultProps = {
  data: [],
};

MapPin.propTypes = {
  coords: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default MapPin;
