import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Typography = ({ children, linkTo }) => {
  const classes = useStyles();

  return (
    linkTo ? <a className={classes.link} href={linkTo}>{children}</a> : <span>{children}</span>
  );
};

Typography.defaultProps = {
  linkTo: '',
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  linkTo: PropTypes.string,
};

export default Typography;
