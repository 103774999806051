import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 250,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 5,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 0px 5px',
  },
  valueText: {
    fontWeight: 'bold',
  },
  imageContainer: {
    minWidth: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '10px 0px',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },
  dashboardSpan: {
    marginRight: 5,
  },
}));

export default useStyles;
