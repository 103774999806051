import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    margin: '15px 0px',
    marginLeft: 25,
    paddingRight: 15,
    borderRight: '1px solid #C7C7C7',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  selectedFilterLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  chipsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  inputContainer: {
    margin: '10px 0px',
  },
  filterChipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 5,
  },
  displayNone: {
    display: 'none',
  },
});

export default useStyles;
