import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    margin: 10,
    borderRadius: 5,
  },
  legendRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, .05)',
    },
  },
  legendIndicator: {
    marginRight: 5,
    width: 15,
    height: 15,
  },
  legendSpan: {
    fontWeight: 'bold',
  },
});

export default useStyles;
