import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';
import Icon from '../Icon';

const Chip = ({ label, onDismiss }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.label}>{label}</span>
      {typeof onDismiss === 'function' && <Icon name="faTimes" onClick={onDismiss} />}
    </div>
  );
};

Chip.defaultProps = {
  onDismiss: null,
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onDismiss: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

export default Chip;
