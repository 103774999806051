import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import useStyles from './styles';
import { isEven } from '../../helpers/math';
import { ITEMS_PER_PAGE } from '../../constants';
import Pagination from '../Pagination';
import Input from '../Input';
import ExcelExport from '../ExcelExport';

let searchDebounce = null;

const Table = ({
  columns, items, getValues, exportSheetName, filterBy,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState([]);
  const [searchText, setSearchText] = useState('');

  const initialValues = {
    keyword: '',
  };

  const {
    values, handleChange,
  } = useFormik({
    initialValues,
  });

  const handlePaginate = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleSearchTextDebounce = useCallback((text) => {
    if (!text) {
      setSearchText('');
      return;
    }

    if (searchDebounce) {
      clearTimeout(searchDebounce);
    }

    searchDebounce = setTimeout(() => {
      setSearchText(text);
    }, 500);
  }, []);

  const handleData = useCallback(() => {
    const actualData = items
      .filter((item) => {
        if (!searchText) {
          return true;
        }

        const textToTest = getValues(item)
          .filter((_, index) => (filterBy.length ? filterBy.includes(index) : true))
          .filter((cellText) => cellText)
          .join(' ');

        const regexToValidate = new RegExp(searchText, 'i');

        return regexToValidate.test(textToTest);
      });

    setData(
      actualData
        .slice(
          (page - 1) * ITEMS_PER_PAGE,
          ((page - 1) * ITEMS_PER_PAGE) + ITEMS_PER_PAGE,
        ),
    );

    setAllData(actualData);
  }, [page, items, searchText, getValues, filterBy]);

  useEffect(() => {
    handleData();
  }, [page, items, searchText, getValues, filterBy, handleData]);

  useEffect(() => {
    setPage(1);
  }, [items]);

  useEffect(() => {
    handleSearchTextDebounce(values.keyword);
  }, [values.keyword, handleSearchTextDebounce]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.searchContainer}>
          <Input
            label="Pesquisar"
            name="keyword"
            onChange={handleChange}
            value={values.keyword}
          />
        </div>
        <div className={classes.buttonContainer}>
          <ExcelExport
            columns={columns}
            data={data.map((item) => getValues(item))}
            sheetName={exportSheetName}
          />
        </div>
      </div>
      <table className={classes.table}>
        <thead>
          <tr className={classes.head}>
            {
            columns.map((column, index) => (
              <th
                key={index.toString()}
                className={classes.th}
              >
                <div className={classnames({
                  [classes.thDiv]: true,
                  [classes.thBorder]: index < columns.length - 1,
                })}
                >
                  {column}
                </div>
              </th>
            ))
        }
          </tr>
        </thead>
        <tbody>
          {
            !data.length && (
              <tr
                className={classnames({
                  [classes.line]: true,
                  [classes.evenLine]: true,
                })}
              >
                <td
                  colSpan={columns.length}
                  className={classnames(classes.td, classes.emptyTd)}
                >
                  Nenhum registro encontrado.
                </td>
              </tr>
            )
          }
          {
            data.map((item, index) => (
              <tr
                key={index.toString()}
                className={classnames({
                  [classes.line]: true,
                  [classes.oddLine]: !isEven(index),
                  [classes.evenLine]: isEven(index),
                })}
              >
                {
                    getValues(item).map((value, itemIndex) => (
                      <td key={itemIndex.toString()} className={classes.td}>{value}</td>
                    ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
      {
        !!data.length && (
        <div className={classes.paginationContainer}>
          <Pagination
            total={allData.length}
            perPage={ITEMS_PER_PAGE}
            page={page}
            onChange={handlePaginate}
          />
        </div>
        )
      }
    </div>
  );
};

Table.defaultProps = {
  filterBy: [],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getValues: PropTypes.func.isRequired,
  exportSheetName: PropTypes.string.isRequired,
  filterBy: PropTypes.arrayOf(PropTypes.number),
};

export default Table;
