import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  get, uniq,
} from 'lodash';
import { useTheme } from '@material-ui/styles';
import moment from 'moment';

import {
  AreaChart,
  BarChart,
  CombinationChart,
  Map, Section, Table,
} from '../../../../components';
import useStyles from './styles';
import GraphSection from '../GraphSection';
import {
  divide, multiply, sum, isEven, ruleOfThree,
} from '../../../../helpers/math';
import {
  format,
} from '../../../../helpers/format';
import { NUMERIC_FORMAT } from '../../../../constants';

const Graphs = ({ farm }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [fields, setFields] = useState([]);
  const [conventionalAreaXIpro, setconventionalAreaXIpro] = useState({});
  const [plantsStand, setPlantsStand] = useState([]);
  const [plantsStandTableData, setPlantsStandTableData] = useState({});
  const [penetrationHistory, setPenetrationHistory] = useState([]);
  const [weatherAndRain, setWeatherAndRain] = useState([]);
  const [conventionalAreaXIproOutput, setconventionalAreaXIproOutput] = useState({});

  const handleFields = useCallback(() => {
    const newFields = [];

    const visits = get(farm, 'visits', {});

    Object.keys(visits).forEach((harvest) => {
      visits[harvest].forEach((visit) => {
        newFields.push(...visit.fields);
      });
    });

    setFields(newFields);
  }, [farm]);

  const handleConventionalAreaGraphData = useCallback(() => {
    const visits = get(farm, 'visits', {});

    const graphs = {};

    Object.keys(visits).forEach((harvest) => {
      if (!graphs[harvest]) {
        graphs[harvest] = [];
      }

      graphs[harvest].push({
        label: harvest,
        values: {
          IPRO: visits[harvest].reduce((previous, current) => sum(previous, get(current, 'area.ipro', 0)), 0),
          'RR/Convencional': visits[harvest].reduce((previous, current) => sum(previous, get(current, 'area.conventional', 0)), 0),
        },
      });
    });

    setconventionalAreaXIpro(graphs);
  }, [farm]);

  const handlePlantsStandGraphData = useCallback(() => {
    const fieldsPerVisit = {};

    fields.forEach((field) => {
      if (!fieldsPerVisit[field.harvest]) {
        fieldsPerVisit[field.harvest] = [];
      }

      fieldsPerVisit[field.harvest].push({
        area: parseFloat(get(field, 'area', 0)),
        plants: get(field, 'plantsM2', 0),
        tech: get(field, 'tech', ''),
      });
    });

    const tableData = {};
    const possibleTechs = [];

    const graphData = Object.keys(fieldsPerVisit).map((harvest) => {
      const visitFields = fieldsPerVisit[harvest];

      visitFields.forEach((field) => {
        if (field.tech && !possibleTechs.includes(field.tech)) {
          possibleTechs.push(field.tech);
        }
      });

      possibleTechs.forEach((tech) => {
        if (!tableData[harvest]) {
          tableData[harvest] = {};
        }

        if (!tableData[harvest][tech]) {
          tableData[harvest][tech] = 0;
        }

        const plantsStandsForTech = visitFields
          .filter((field) => field.tech === tech)
          .reduce((previous, current) => sum(previous, multiply(current.area, current.plants)), 0);

        const fieldAreasForTech = visitFields
          .reduce((previous, current) => sum(previous, current.area), 0);

        tableData[harvest][tech] = fieldAreasForTech
          ? divide(plantsStandsForTech, fieldAreasForTech)
          : 0;
      });

      const plantsStands = visitFields
        .reduce((previous, current) => sum(previous, multiply(current.area, current.plants)), 0);

      const fieldAreas = visitFields
        .reduce((previous, current) => sum(previous, current.area), 0);

      return {
        x: harvest,
        y: divide(plantsStands, fieldAreas),
      };
    });

    if (graphData.length) {
      graphData.unshift({
        x: 0,
        y: 0,
      });
    }

    setPlantsStandTableData(tableData);
    setPlantsStand(graphData);
  }, [fields]);

  const handlePenetrationHistoryGraphData = useCallback(() => {
    const harvests = uniq(fields.map((field) => field.harvest));

    const values = {};

    harvests.forEach((harvest) => {
      const iproArea = fields
        .filter((field) => field.harvest === harvest)
        .filter((field) => field.tech === 'IPRO')
        .reduce((previous, current) => sum(previous, current.area), 0);

      const totalArea = fields
        .filter((field) => field.harvest === harvest)
        .reduce((previous, current) => sum(previous, current.area), 0);

      values[harvest] = multiply(divide(iproArea, totalArea), 100);
    });

    setPenetrationHistory([{
      label: 'A',
      values,
    }]);
  }, [fields]);

  const handleWeatherGraphData = useCallback(() => {
    const weather = get(farm, 'weather', []).map((currentWeather) => ({
      x: moment(currentWeather.date).format('MMM/YYYY'),
      line: currentWeather.temperature,
      bar: currentWeather.precipitation,
    }));

    setWeatherAndRain(weather);
  }, [farm]);

  const handleOutputGraphData = useCallback(() => {
    const output = get(farm, 'output', []);
    const visits = get(farm, 'visits', []);

    const graphs = output.reduce((previous, current) => {
      const farmAreas = get(
        visits,
        `${current.harvest}[${get(visits, current.harvest, []).length - 1}].area`,
        false,
      );

      if (!farmAreas) {
        return previous;
      }

      const totalArea = sum(
        get(farmAreas, 'ipro', 0),
        get(farmAreas, 'conventional', 0),
      );

      return {
        ...previous,
        [current.harvest]: [
          ...get(previous, current.harvest, []),
          {
            label: current.harvest,
            values: {
              IPRO: ruleOfThree(totalArea, get(current, 'output', 0), get(farmAreas, 'ipro', 0)),
              'RR/Convencional': ruleOfThree(totalArea, get(current, 'output', 0), get(farmAreas, 'conventional', 0)),
            },
          }],
      };
    }, {});

    setconventionalAreaXIproOutput(graphs);
  }, [farm]);

  useEffect(() => {
    handlePlantsStandGraphData();
    handlePenetrationHistoryGraphData();
  }, [fields, handlePlantsStandGraphData, handlePenetrationHistoryGraphData]);

  useEffect(() => {
    handleFields();
    handleConventionalAreaGraphData();
    handleWeatherGraphData();
    handleOutputGraphData();
  },
  [
    farm, handleConventionalAreaGraphData,
    handleFields, handleWeatherGraphData, handleOutputGraphData,
  ]);

  return (
    <div className={classes.container}>
      <div className={classes.graphsContainer}>
        <div className={classes.row}>
          <GraphSection
            title="Estande de Plantas (Plantas/m²)"
          >
            <div className={classnames(classes.graphContainer)}>
              <AreaChart
                data={plantsStand}
              />
            </div>
            <div className={classes.tableContainer}>
              <div className={classnames(classes.table)}>
                {
                  Object.keys(plantsStandTableData).map((harvest, index) => (
                    <div
                      key={index.toString()}
                      className={classnames(classes.row, classes.border1, classes.flex1)}
                    >
                      <div className={classnames(
                        classes.column,
                        classes.flex1,
                        classes.center,
                        classes.background1,
                      )}
                      >
                        {harvest}
                      </div>
                      <div className={classnames(classes.column, classes.flex3)}>
                        {
                            Object.keys(plantsStandTableData[harvest])
                              .map((tech, techIndex) => (
                                <div
                                  key={techIndex.toString()}
                                  className={classnames({
                                    [classes.spaceBetween]: true,
                                    [classes.background1]: isEven(techIndex),
                                    [classes.background2]: !isEven(techIndex),
                                    [classes.subRow]: true,
                                  })}
                                >
                                  <span>{tech}</span>
                                  <span>
                                    {format(plantsStandTableData[harvest][tech], NUMERIC_FORMAT)}
                                  </span>
                                </div>
                              ))
                          }
                      </div>
                      <div className={classnames(
                        classes.column,
                        classes.flex1,
                        classes.center,
                        classes.background1,
                      )}
                      >
                        {
                            format(Object.keys(plantsStandTableData[harvest])
                              .reduce(
                                (previous, current) => sum(
                                  previous,
                                  plantsStandTableData[harvest][current],
                                ),
                                0,
                              ),
                            NUMERIC_FORMAT)
                          }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </GraphSection>
          <GraphSection
            title="Clima x Pluviosidade"
          >
            <div className={classes.graphContainer}>
              <CombinationChart
                barAxisTitle="(mm)"
                lineAxisTitle="(°C)"
                lineColor={theme.palette.error.main}
                data={{
                  label: 'A',
                  values: weatherAndRain,
                }}
              />
            </div>
          </GraphSection>
          <div className={classnames(classes.flex1, classes.sectionContainer)}>
            <Section className={classes.flex1}>
              <Map
                style={{
                  borderRadius: 4,
                }}
                initialCenter={{
                  lat: -13.5409218,
                  lng: -58.1019205,
                }}
                zoom={10.25}
                kmls={get(farm, 'kml', []).map((url) => ({
                  url,
                }))}
              />
            </Section>
          </div>
        </div>
        <GraphSection
          title="Histórico Área Convencional x IPRO (ha)"
        >
          <div className={classes.graphContainer}>
            <div className={classnames(classes.flex1, classes.row)}>
              {
                    Object.keys(conventionalAreaXIpro).map((harvest, index) => (
                      <div key={index.toString()} className={classes.graphContainer}>
                        <div className={classes.graphHeaderContainer}>
                          {harvest}
                          <div className={classes.graphHeader} />
                        </div>
                        <BarChart
                          data={conventionalAreaXIpro[harvest]}
                        />
                      </div>
                    ))
                  }
            </div>
          </div>
        </GraphSection>
        <GraphSection
          title="Histórico Produção Convencional x IPRO (t)*"
        >
          <div className={classnames(classes.flex1, classes.row)}>
            {
              Object.keys(conventionalAreaXIproOutput).map((harvest, index) => (
                <div key={index.toString()} className={classes.graphContainer}>
                  <div className={classes.graphHeaderContainer}>
                    {harvest}
                    <div className={classes.graphHeader} />
                  </div>
                  <BarChart
                    data={conventionalAreaXIproOutput[harvest]}
                  />
                </div>
              ))
            }
          </div>
          <div className={classes.graphSubTitle}>
            *Estimativa de Produção (t) por talhão calculada com base na estimativa
            de Produtividade Estadual da CONAB (t/ha), atualizada em Novembro/2020.
          </div>
        </GraphSection>
        <GraphSection
          title="Histórico Penetração (%)"
        >
          <div className={classes.graphContainer}>
            <BarChart
              horizontal
              xAxisValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              data={penetrationHistory}
            />
          </div>
        </GraphSection>
      </div>
      <div className={classes.tableContainer}>
        <Table
          exportSheetName="Talhões"
          columns={[
            'Talhão',
            'Nome fazenda',
            'Safra',
            'Tecnologia',
            'Produção (t)',
            'Latitude talhão',
            'Longitude talhão',
            'Área total talhão (ha)',
            'Plantas/m²',
            'Estande de plantas (Plantas/m)',
            'Espaçamento (cm)',
          ]}
          items={fields}
          getValues={(item) => [
            item.name,
            item.farmName,
            item.harvest,
            item.tech,
            format(item.output, NUMERIC_FORMAT),
            item.latitude,
            item.longitude,
            format(item.area, NUMERIC_FORMAT),
            format(item.plantsM2, NUMERIC_FORMAT),
            format(item.plantsStand, NUMERIC_FORMAT),
            format(item.space, NUMERIC_FORMAT),
          ]}
        />
      </div>
    </div>
  );
};

Graphs.defaultProps = {
  farm: {
    visits: {},
    kml: [],
    weather: [],
  },
};

Graphs.propTypes = {
  farm: PropTypes.shape({
    visits: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({}),
    }),
    kml: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default Graphs;
