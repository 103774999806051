import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, RadioGroup } from '../../../../components';
import { removeDuplicates } from '../../../../helpers/array';
import useStyles from './styles';
import {
  DISTRICT_REFERENCE, FARM_REFERENCE, OWNER_REFERENCE, REGION_REFERENCE,
} from '../../../../constants';

const SearchBar = ({
  options,
  filters,
  onChange,
  vision,
  onVisionChange,
}) => {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState(null);

  const handleFilterChange = useCallback((value, key) => {
    onChange({
      vision,
      filters: {
        ...filters,
        [key]: value.map(({ id }) => id),
      },
    });
  }, [filters, vision, onChange]);

  const handleFilterInit = useCallback((optionsToFilter, filtersToUse) => {
    const regions = get(optionsToFilter, 'regions', []);
    const districts = get(optionsToFilter, 'districts', []);
    const owners = get(optionsToFilter, 'owners', []);
    const farms = get(optionsToFilter, 'farms', []);

    const regionsToFilter = get(filtersToUse, 'region', []);
    const districtsToFilter = get(filtersToUse, 'district', []);
    const ownersToFilter = get(filtersToUse, 'owner', []);

    const filteredDistrict = districts.filter((district) => {
      if (regions.length) {
        return regionsToFilter.includes(district.regionId);
      }

      return true;
    });

    const filteredOwners = removeDuplicates(owners.filter((owner) => {
      let isValid = true;

      if (
        regionsToFilter.length
        && !regionsToFilter.includes(owner.regionId)
      ) {
        isValid = false;
      }

      if (
        districtsToFilter.length
        && !districtsToFilter.includes(owner.districtId)
      ) {
        isValid = false;
      }

      return isValid;
    }));

    const filterFarms = removeDuplicates(farms.filter((farm) => {
      let isValid = true;

      if (
        regionsToFilter.length
        && !regionsToFilter.includes(farm.regionId)
      ) {
        isValid = false;
      }

      if (
        districtsToFilter.length
        && !districtsToFilter.includes(farm.districtId)
      ) {
        isValid = false;
      }

      if (
        ownersToFilter.length
        && !ownersToFilter.includes(farm.ownerId)
      ) {
        isValid = false;
      }

      return isValid;
    }));

    setFilterOptions({
      regions,
      districts: filteredDistrict,
      owners: filteredOwners,
      farms: filterFarms,
    });
  }, []);

  useEffect(() => {
    handleFilterInit(options, filters);
  }, [options, filters, handleFilterInit]);

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <div className={classes.visionOptionContainer}>
          <RadioGroup
            title="Visão:"
            onChange={onVisionChange}
            value={vision}
            options={[{
              label: 'Regional',
              value: REGION_REFERENCE,
            }, {
              label: 'Distrito',
              value: DISTRICT_REFERENCE,
            }, {
              label: 'Produtor',
              value: OWNER_REFERENCE,
            }, {
              label: 'Fazenda',
              value: FARM_REFERENCE,
            }]}
          />
        </div>
        <div className={classes.inputRow}>
          <div className={classes.inputContainer}>
            <Autocomplete
              multiple
              value={get(filters, 'region', [])}
              label="Regional"
              options={get(filterOptions, 'regions', [])}
              onChange={(value) => handleFilterChange(value, 'region')}
            />
          </div>
          <div className={classes.inputContainer}>
            <Autocomplete
              multiple
              value={get(filters, 'district', [])}
              label="Distrito"
              options={get(filterOptions, 'districts', [])}
              onChange={(value) => handleFilterChange(value, 'district')}
            />
          </div>
          <div className={classes.inputContainer}>
            <Autocomplete
              readOnly={[REGION_REFERENCE].includes(vision)}
              multiple
              value={get(filters, 'owner', [])}
              label="Produtor"
              options={get(filterOptions, 'owners', [])}
              onChange={(value) => handleFilterChange(value, 'owner')}
            />
          </div>
          <div className={classes.inputContainer}>
            <Autocomplete
              readOnly={[DISTRICT_REFERENCE, REGION_REFERENCE].includes(vision)}
              multiple
              value={get(filters, 'farm', [])}
              label="Fazenda"
              options={get(filterOptions, 'farms', [])}
              onChange={(value) => handleFilterChange(value, 'farm')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBar.defaultProps = {
  options: {},
  filters: {},
  vision: FARM_REFERENCE,
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({}),
  filters: PropTypes.shape({}),
  vision: PropTypes.oneOf([
    FARM_REFERENCE,
    OWNER_REFERENCE,
    DISTRICT_REFERENCE,
    REGION_REFERENCE,
  ]),
  onVisionChange: PropTypes.func.isRequired,
};

export default SearchBar;
