import React, { useState } from 'react';
import {
  DiscreteColorLegend,
  FlexibleXYPlot, Hint, HorizontalBarSeries, HorizontalGridLines,
  VerticalBarSeries, VerticalGridLines, XAxis, YAxis,
} from 'react-vis';
import PropTypes from 'prop-types';
import { max, min } from 'lodash';

import { chartAxisFormatter, format } from '../../helpers/format';
import { multiply } from '../../helpers/math';
import useStyles from './styles';
import { messages } from '../../helpers/chart';
import ChartHint from '../ChartHint';
import { useGraphItemOpacity } from '../../hooks';

const BarChart = ({
  data, horizontal, xAxisValues, legendStyle, showLegend,
  showHint,
}) => {
  const classes = useStyles();
  const [hoveredNode, setHoveredNode] = useState(null);
  const { handleFocusedItemChange, getItemOpacity } = useGraphItemOpacity();

  const Bar = horizontal ? HorizontalBarSeries : VerticalBarSeries;

  const plotProps = {};

  if (!horizontal) {
    plotProps.xType = 'ordinal';
  } else {
    plotProps.yType = 'ordinal';

    plotProps.xDomain = [min(xAxisValues), max(xAxisValues)];

    let maxYLenght = 0;

    data
      .map((bar) => bar.values)
      .forEach((item) => Object.keys(item)
        .forEach((key) => {
          if (maxYLenght < key.length) {
            maxYLenght = key.length;
          }
        }));

    plotProps.margin = {
      left: multiply(maxYLenght, 8),
    };
  }

  return data.length ? (
    <FlexibleXYPlot
      stackBy={horizontal ? 'x' : 'y'}
      {...plotProps}
      onMouseLeave={() => setHoveredNode(null)}
    >
      {showLegend && (
      <DiscreteColorLegend
        onItemClick={handleFocusedItemChange}
        style={legendStyle}
        orientation="horizontal"
        items={data.map((item) => ({
          title: item.label,
          strokeWidth: 15,
        }))}
      />
      )}
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis
        tickFormat={!horizontal ? null : chartAxisFormatter}
        tickValues={xAxisValues}
      />
      <YAxis
        tickFormat={horizontal ? null : chartAxisFormatter}
      />
      {
        data.map((item, index) => (
          <Bar
            onValueMouseOver={(d) => setHoveredNode({
              ...d,
              ...item,
            })}
            barWidth={0.7}
            opacity={getItemOpacity(item.label)}
            key={index.toString()}
            cluster={item.label}
            data={Object.keys(item.values).map((key) => ({
              x: horizontal ? Number(item.values[key]) : key,
              y: horizontal ? key : Number(item.values[key]),
            }))}
          />
        ))
      }
      {hoveredNode && showHint && (
      <Hint
        xType={horizontal ? 'literal' : 'ordinal'}
        yType={horizontal ? 'ordinal' : 'literal'}
        getX={(d) => (horizontal ? 0 : d.x)}
        getY={(d) => (horizontal ? d.y : 0)}
        value={{
          x: hoveredNode.x,
          y: hoveredNode.y,
        }}
      >
        <ChartHint>
          {`${horizontal ? hoveredNode.y : hoveredNode.label}: ${horizontal ? format(hoveredNode.x) : format(hoveredNode.y)}`}
        </ChartHint>
      </Hint>
      )}
    </FlexibleXYPlot>
  ) : (
    <div className={classes.emptyContaier}>
      {messages.EMPTY_DATA}
    </div>
  );
};

BarChart.defaultProps = {
  horizontal: false,
  xAxisValues: null,
  legendStyle: {},
  showLegend: false,
  showHint: false,
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    values: PropTypes.shape({}).isRequired,
  })).isRequired,
  horizontal: PropTypes.bool,
  xAxisValues: PropTypes.arrayOf(PropTypes.number),
  legendStyle: PropTypes.shape({}),
  showLegend: PropTypes.bool,
  showHint: PropTypes.bool,
};

export default BarChart;
