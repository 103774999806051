import React, { useState } from 'react';
import { MenuItem, Menu as MuiMenu } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

import Link from '../Link';

const Menu = ({ options, children }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        className={classes.buttonContainer}
        onClick={handleClick}
      >
        {children}
      </div>
      <MuiMenu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <Link
            to={option.to}
          >
            <MenuItem
              key={option.value}
            >
              {option.value}
            </MenuItem>
          </Link>
        ))}
      </MuiMenu>
    </div>
  );
};

Menu.defaultProps = {
  options: [],
};

Menu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    to: Link.propTypes.to,
  })),
  children: PropTypes.node.isRequired,
};

export default Menu;
