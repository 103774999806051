import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F0F0F0',
    display: 'flex',
    padding: '10px 0px',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

export default useStyles;
