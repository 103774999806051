import React, { useCallback, useEffect, useState } from 'react';
import {
  ArcSeries, FlexibleXYPlot, DiscreteColorLegend,
} from 'react-vis';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { degToRad } from '../../helpers/math';

const COLORS = [
  '#6588cd',
  '#66b046',
  '#a361c7',
  '#ad953f',
  '#c75a87',
  '#55a47b',
  '#cb6141',
];

const PieChart = ({ data }) => {
  const [pieData, setPieData] = useState([]);
  const [legend, setLegend] = useState([]);

  const handlePieData = useCallback((pieDataToHandle) => {
    const total = Object.values(pieDataToHandle).reduce((a, b) => a + b);

    const manipulatedData = [];
    const manipulatedLegend = [];

    Object.keys(pieDataToHandle).forEach((key, index) => {
      const lastAngle = get(manipulatedData[manipulatedData.length - 1], 'value', 0);

      const pct = (pieDataToHandle[key] / total) * 100;
      const degree = pct * 3.6;
      const rad = degToRad(degree);

      manipulatedData.push({
        value: rad + lastAngle,
        radius0: 0,
        radius: 4,
        color: COLORS[index],
        lastValue: lastAngle,
      });

      manipulatedLegend.push(key);
    });

    setPieData(manipulatedData);
    setLegend(manipulatedLegend);
  }, []);

  useEffect(() => {
    handlePieData(data);
  }, [data, handlePieData]);

  return (
    <>
      <DiscreteColorLegend
        items={legend}
        colors={COLORS}
        width={100}
        // height={300}
      />
      <FlexibleXYPlot
        getAngle={(d) => d.value}
        getAngle0={(d) => d.lastValue}
        xDomain={[-3, 3]}
        yDomain={[-3, 3]}
      >
        <ArcSeries
          colorType="literal"
          animation
          radiusDomain={[3, 3]}
          data={pieData}
        />
      </FlexibleXYPlot>
    </>
  );
};

PieChart.defaultProps = {
  data: {},
};

PieChart.propTypes = {
  data: PropTypes.shape({
    [PropTypes.oneOfType([PropTypes.string, PropTypes.number])]: PropTypes.number,
  }),
};

export default PieChart;
