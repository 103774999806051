import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#C7C7C733',
    padding: 3,
    margin: 5,
    textTransform: 'uppercase',
    display: 'flex',
  },
  label: {
    marginRight: 5,
  },
});

export default useStyles;
