import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { get } from 'lodash';
import { parse } from 'query-string';
import { LoadingWrapper, Template } from '../../components';
import { Koogle } from '../../services';
import { SearchBar, Graphs } from './components';
import useStyles from './styles';
import {
  DISTRICT_REFERENCE, FARM_REFERENCE, OWNER_REFERENCE, REGION_REFERENCE,
} from '../../constants';

const Dashboard = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [cachedData, setCachedData] = useState(null);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({});
  const [vision, setVision] = useState(FARM_REFERENCE);
  const [filterOptions, setFilterOptions] = useState({
    regions: [],
    districts: [],
    owners: [],
    farms: [],
  });

  const loadFilterOptions = useCallback((regionsAndHarvests, results) => {
    const regions = get(regionsAndHarvests, 'regions', []).map(({ id, name }) => ({
      id,
      label: name,
    }));

    const districts = get(regionsAndHarvests, 'regions', [])
      .reduce((previous, current) => [
        ...previous,
        ...get(current, 'districts', []).map(({ id, name }) => ({
          id,
          label: name,
          regionId: current.id,
        })),
      ], []);

    const owners = results.map(({ owner, district, region }) => ({
      id: owner.id,
      label: owner.name,
      regionId: region.id,
      districtId: district.id,
    }));

    const farms = results.map(({
      farm, district, region, owner,
    }) => ({
      id: farm.id,
      label: farm.name,
      regionId: region.id,
      districtId: district.id,
      ownerId: owner.id,
    }));

    setFilterOptions({
      regions,
      districts,
      owners,
      farms,
    });
  }, []);

  const loadData = useCallback(async ({ regions = [], districts = [] }) => {
    setIsLoading(true);

    const regionsAndHarvests = await Koogle.getRegionsAndHarvest();
    const newData = await Koogle.getDashboardResults({ regions, districts });

    loadFilterOptions(regionsAndHarvests, get(newData, 'visits', []));

    setCachedData(newData);
    setIsLoading(false);
  }, [loadFilterOptions]);

  const handleFilterChange = useCallback(({ vision: changedVision, filters: changedFilters }) => {
    const region = get(changedFilters, 'region', []);
    const district = get(changedFilters, 'district', []);
    const owner = get(changedFilters, 'owner', []);
    const farm = get(changedFilters, 'farm', []);

    setFilters({
      region,
      district,
      owner,
      farm,
    });

    setVision(changedVision);
  }, []);

  const handleVisionChange = useCallback((newVision) => {
    setVision(newVision);
  }, []);

  const filterActualData = useCallback(async (filterToUse, dataToFilter) => {
    const visits = get(dataToFilter, 'visits', []);

    const filteredVisits = visits.filter((visit) => {
      let isValid = true;

      if (
        get(filterToUse, 'region', []).length
        && !get(filterToUse, 'region', []).includes(visit.region.id)
      ) {
        isValid = false;
      }

      if (
        get(filterToUse, 'district', []).length
        && !get(filterToUse, 'district', []).includes(visit.district.id)
      ) {
        isValid = false;
      }

      if (
        get(filterToUse, 'owner', []).length
        && !get(filterToUse, 'owner', []).includes(visit.owner.id)
      ) {
        isValid = false;
      }

      if (
        get(filterToUse, 'farm', []).length
        && !get(filterToUse, 'farm', []).includes(visit.farm.id)
      ) {
        isValid = false;
      }

      return isValid;
    });

    setData({
      ...dataToFilter,
      visits: filteredVisits,
    });
  }, []);

  useEffect(() => {
    const parsedFilter = parse(window.location.search);

    const urlRegions = get(parsedFilter, 'region', []);
    const urlDistricts = get(parsedFilter, 'district', []);
    const urlOwners = get(parsedFilter, 'owner', []);
    const urlFarms = get(parsedFilter, 'farm', []);
    const urlVision = get(parsedFilter, 'vision', FARM_REFERENCE);

    const regions = Array.isArray(urlRegions) ? urlRegions : [urlRegions];
    const districts = Array.isArray(urlDistricts) ? urlDistricts : [urlDistricts];
    const owners = Array.isArray(urlOwners) ? urlOwners : [urlOwners];
    const farms = Array.isArray(urlFarms) ? urlFarms : [urlFarms];

    let initialFilters = {
      region: regions,
      district: districts,
      owner: owners,
      farm: farms,
    };

    if ([DISTRICT_REFERENCE, REGION_REFERENCE, OWNER_REFERENCE].includes(urlVision)) {
      initialFilters = {
        ...initialFilters,
        farm: [],
        owner: [REGION_REFERENCE, DISTRICT_REFERENCE].includes(urlVision)
          ? []
          : initialFilters.owner,
        district: [REGION_REFERENCE].includes(urlVision) ? [] : initialFilters.district,
      };
    }

    handleFilterChange({
      vision: urlVision,
      filters: initialFilters,
    });
  }, [handleFilterChange, loadData]);

  useEffect(() => {
    if (
      !!filters.region
      && !!filters.district
    ) {
      loadData({
        regions: filters.region,
        districts: filters.district,
      });
    }
  }, [filters.region, filters.district, loadData]);

  useEffect(() => {
    if (cachedData) {
      filterActualData(filters, cachedData);
    }
  }, [filters, cachedData, filterActualData]);

  useEffect(() => {
    if ([DISTRICT_REFERENCE, REGION_REFERENCE, OWNER_REFERENCE].includes(vision)) {
      setFilters((actualFilters) => ({
        ...actualFilters,
        farm: [],
        owner: [REGION_REFERENCE, DISTRICT_REFERENCE].includes(vision) ? [] : actualFilters.owner,
        district: [REGION_REFERENCE].includes(vision) ? [] : actualFilters.district,
      }));
    }
  }, [vision]);

  return (
    <Template>
      <SearchBar
        vision={vision}
        filters={filters}
        options={filterOptions}
        onChange={handleFilterChange}
        onVisionChange={handleVisionChange}
      />
      <div className={classnames({
        [classes.root]: isLoading,
      })}
      >
        <LoadingWrapper isLoading={isLoading}>
          <Graphs
            data={data}
            vision={vision}
          />
        </LoadingWrapper>
      </div>
    </Template>
  );
};

export default Dashboard;
