import React from 'react';
import { Switch } from 'react-router-dom';

import routes from './routes';
import * as pages from '../pages';
import { PublicRoute, PrivateRoute } from './components';

const Routes = () => (
  <Switch>
    {
      Object.keys(routes).map((route) => {
        if (!routes[route].private) {
          return <PublicRoute key={route} exact path={`${routes[route].path}`} component={pages[routes[route].component]} />;
        }

        return <PrivateRoute key={route} exact path={`${routes[route].path}`} component={pages[routes[route].component]} />;
      })
    }
  </Switch>
);

export default Routes;
