import { ActionTypes } from '../actions';

const initialState = {
  kmls: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.KML_STORE_KMLS:
      return {
        ...state,
        kmls: action.payload.kmls,
      };
    case ActionTypes.KML_CLEAR_KMLS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
