import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const RadioGroup = ({
  title, options, value, onChange,
}) => {
  const classes = useStyles();

  const handleOptionClick = useCallback((event) => {
    onChange(event.target.getAttribute('value'));
  }, [onChange]);

  return (
    <>
      {
            title && (
            <div className={classes.title}>
              {title}
            </div>
            )
        }
      {
        options.map(({ label, value: optionValue }, index) => (
          <div
            className={classnames({
              [classes.root]: true,
              [classes.optionSelected]: value === optionValue,
            })}
            key={index.toString()}
            value={optionValue}
            onClick={handleOptionClick}
          >
            {label}
          </div>
        ))
    }
    </>
  );
};

RadioGroup.defaultProps = {
  options: [],
  value: null,
  title: '',
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
};

export default RadioGroup;
