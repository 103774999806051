import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../Loading';

const LoadingWrapper = ({ children, isLoading }) => (isLoading ? (
  <Loading />
) : children);

LoadingWrapper.defaultProps = {
  isLoading: false,
};

LoadingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

export default LoadingWrapper;
