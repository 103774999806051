import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { get } from 'lodash';
import useStyles from './styles';
import Card from '../Card';
import { Pagination } from '../../../../components';
import { ITEMS_PER_PAGE } from '../../../../constants';
import { Koogle } from '../../../../services';

const List = ({ data }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [cards, setCards] = useState([]);
  const [imageCache, setImageCache] = useState({});

  const handlePaginate = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const loadCardPhotos = useCallback(async () => {
    const farmIdsToLoad = cards
      .map(({ farm }) => farm.id)
      .filter((item) => !Object.keys(imageCache).includes(item));

    if (farmIdsToLoad.length) {
      const photos = await Koogle.getFarmsPhoto(farmIdsToLoad);

      let newImageCache = {};

      farmIdsToLoad.forEach((farmId) => {
        newImageCache[farmId] = null;
      });

      newImageCache = {
        ...newImageCache,
        ...imageCache,
        ...photos,
      };

      setImageCache(newImageCache);
    }
  }, [cards, setImageCache, imageCache]);

  useEffect(() => {
    setCards(
      data.slice(
        (page - 1) * ITEMS_PER_PAGE,
        ((page - 1) * ITEMS_PER_PAGE) + ITEMS_PER_PAGE,
      ),
    );
  }, [page, data]);

  useEffect(() => {
    loadCardPhotos();
  }, [cards, loadCardPhotos]);

  useEffect(() => {
    setPage(1);
  }, [data]);

  return (
    <div className={classes.container}>
      <div className={classnames({
        [classes.paginationContainer]: true,
        [classes.displayNone]: data.length <= ITEMS_PER_PAGE,
      })}
      >
        <Pagination
          total={data.length}
          page={page}
          onChange={handlePaginate}
          perPage={ITEMS_PER_PAGE}
        />
      </div>
      {
        !cards.length && (
          <div className={classes.emptyContainer}>
            <span className={classes.emptyText}>Nenhum resultado encontrado.</span>
          </div>
        )
      }
      {
        cards
          .map((card, index) => (
            <Card
              key={get(card, 'id', index).toString()}
              {...card}
              image={get(imageCache, card.farm.id, { isLoading: true })}
            />
          ))
      }
      <div className={classnames({
        [classes.paginationContainer]: true,
        [classes.displayNone]: data.length <= ITEMS_PER_PAGE,
      })}
      >
        <Pagination
          total={data.length}
          page={page}
          onChange={handlePaginate}
          perPage={ITEMS_PER_PAGE}
        />
      </div>
    </div>
  );
};

List.defaultProps = {
  data: [],
};

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    farm: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    owner: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      identity: PropTypes.string.isRequired,
    }).isRequired,
    district: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    city: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    state: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    region: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  })),
};

export default List;
