import API from '../API';
import Notify from './Notify';
import { store } from '../store';
import { clearToken, storeToken } from '../store/actions/auth';
import { clearUser, storeUser } from '../store/actions/user';
import { clearKmls } from '../store/actions/kml';

const Auth = {
  login: async ({ login, password } = {}) => {
    try {
      const { request } = API.post('/user/login', {
        login,
        password,
      });

      const { data: { token, user } } = await request;

      store.dispatch(storeToken(token));
      store.dispatch(storeUser({
        id: user.id,
        name: user.nm_usuario,
        login: user.login,
        email: user.email,
      }));

      return {
        token,
        user: {
          id: user.id,
          name: user.nm_usuario,
          login: user.login,
          email: user.email,
        },
      };
    } catch ({ message }) {
      return Notify.error(message);
    }
  },
  logout: () => {
    store.dispatch(clearToken());
    store.dispatch(clearUser());
    store.dispatch(clearKmls());
  },
};

export default Auth;
