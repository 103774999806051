import React from 'react';

import useStyles from './styles';

const Banner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container} />
    </div>
  );
};

export default Banner;
