import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  valueText: {
    marginLeft: 5,
    fontWeight: 'bold',
  },
});

export default useStyles;
