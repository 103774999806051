import { find, get, groupBy } from 'lodash';
import moment from 'moment';

import Notify from './Notify';
import API from '../API';
import { multiply } from '../helpers/math';

const Koogle = {
  getRegionsAndHarvest: async () => {
    try {
      const { request } = API.post('/koogle/endpoint', {
        action: 'getUserRegiaosDistritos',
      });

      const { regioes: districts, safras: harvests } = await request;

      const groupedByRegion = groupBy(districts, 'regiao_id');

      const handledRegions = Object.keys(groupedByRegion).map((regionId) => {
        const districtsInRegion = groupedByRegion[regionId];

        return {
          id: regionId,
          name: get(districtsInRegion, '[0].nm_regiao'),
          districts: groupedByRegion[regionId].map(({
            distrito_id: districtId,
            nm_distrito: districtName,
          }) => ({
            id: districtId,
            name: districtName,
          })),
        };
      });

      return {
        regions: handledRegions,
        harvests: harvests.map((harvest) => ({
          id: harvest.id,
          name: harvest.ano_safra,
        })),
      };
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
  getHomeResults: async () => {
    try {
      const { request } = API.post('/koogle/endpoint', {
        action: 'getResultadosPorRegiaoDistrito',
      });

      const homeResults = await request;

      return homeResults.map((result) => ({
        id: result.id,
        city: {
          id: result.cidade_id,
          name: result.nm_cidade,
        },
        district: {
          id: result.distrito_id,
          name: result.nm_distrito,
        },
        kuhlmannGeoJson: JSON.parse(JSON.parse(result.kmljson)),
        carGeoJson: JSON.parse(JSON.parse(result.carjson)),
        state: {
          id: result.estado_id,
          name: result.sigla_estado,
        },
        farm: {
          id: result.fazenda_id,
          name: result.nm_fazenda,
        },
        owner: {
          name: result.nm_produtor,
          id: result.produtor_id,
          identity: result.cpf_cnpj,
        },
        region: {
          id: result.regiao_id,
          name: result.nm_regiao,
        },
        harvest: {
          id: result.safra_id,
        },
      }));
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
  getFarmsPhoto: async (farmIds = []) => {
    try {
      const { request } = API.post('/koogle/endpoint', {
        action: 'getFotoFachada',
        params: {
          fazendas_id: Array.isArray(farmIds) ? farmIds : [farmIds],
        },
      });

      const farmPhotos = await request;

      const pictures = {};

      farmPhotos.forEach(({ fazenda_id: farmId, image }) => {
        pictures[farmId] = image ? {
          base64: image.encoded,
        } : null;
      });

      return pictures;
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
  getFarmsPhotos: async (farmIds = []) => {
    try {
      const { request } = API.post('/koogle/endpoint', {
        action: 'getFotosFazenda',
        params: {
          fazendas_id: Array.isArray(farmIds) ? farmIds : [farmIds],
        },
      });

      const farmPhotos = await request;

      return farmPhotos.map(({ image }) => image.encoded);
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
  getFarm: async (farmId = null) => {
    try {
      const { request } = API.post('/koogle/endpoint', {
        action: 'getDetalharArea',
        params: {
          fazenda_id: farmId,
        },
      });

      const {
        dados_area: farmResult = [],
        dados_pluviosidade: weatherResult = [],
        kml = [],
        produtividade_area: outputResult = [],
      } = await request;

      const visits = {};

      const output = outputResult.map((outputItem) => ({
        harvest: outputItem.ano_safra,
        output: Number(outputItem.produtividade_kg_ha),
      }));

      const weather = weatherResult
        .map((weatherItem) => ({
          date: weatherItem.periodo,
          precipitation: Number(weatherItem.precipitacao_total),
          temperature: Number(weatherItem.temperatura_ar),
        })).sort((a, b) => moment
          .utc(a.date).diff(moment.utc(b.date)));

      farmResult.map((item) => ({
        ...item,
        areas_totais: JSON.parse(item.areas_totais),
        attributes: JSON.parse(item.attributes),
      })).forEach((item) => {
        if (!visits[item.ano_safra]) {
          visits[item.ano_safra] = [];
        }

        const harvestOutput = output
          .reduce((previous, current) => (current.harvest === item.ano_safra
            ? current.output
            : previous),
          0);

        visits[item.ano_safra].push({
          harvest: item.ano_safra,
          date: item.created_at,
          area: {
            conventional: get(item, 'areas_totais.area_auditada_conv', 0),
            ipro: get(item, 'areas_totais.area_auditada_ipro', 0),
          },
          fields: get(item.attributes, 'talhoes', []).map((field) => ({
            id: field.id_talhao,
            name: field.talhao,
            tech: field.resultado.tecnologia,
            farmName: item.nm_fazenda,
            harvest: item.ano_safra,
            space: field.resultado.espacamento_semeio,
            plantsM2: field.resultado.planta_metro_quadrado,
            plantsStand: field.resultado.stand,
            area: field.resultado.area,
            output: multiply(harvestOutput, field.resultado.area),
          })),
        });

        visits[item.ano_safra].sort((a, b) => moment
          .utc(a.date).diff(moment.utc(b.date)));
      });

      return {
        visits,
        kml,
        weather,
        output,
      };
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
  getDashboardResults: async ({ regions = [], districts = [] } = {}) => {
    try {
      const { request } = API.post('/koogle/endpoint', {
        action: 'getDashboardArea',
        params: {
          regiaos_id: regions,
          distritos_id: districts,
        },
      });

      const {
        dados_area: visitResults = [],
        produtividade_area: outputResult = [],
        dados_pluviosidade: weatherResult = [],
      } = await request;

      const visits = visitResults.map((visit) => ({
        ...visit,
        attributes: JSON.parse(get(visit, 'attributes', '{}')),
        areas_totais: JSON.parse(get(visit, 'areas_totais', '{}')),
      })).map((visit) => {
        let carjson = JSON.parse(get(visit, 'carjson', '{}') || '{}');
        let kmljson = JSON.parse(get(visit, 'kmljson', '{}') || '{}');

        carjson = typeof carjson === 'string' ? JSON.parse(carjson) : carjson;
        kmljson = typeof kmljson === 'string' ? JSON.parse(kmljson) : kmljson;

        return ({
          id: visit.id,
          farm: {
            id: visit.fazenda_id,
            name: visit.nm_fazenda,
          },
          region: {
            id: visit.regiao_id,
            name: visit.nm_regiao,
          },
          district: {
            id: visit.distrito_id,
            name: visit.nm_distrito,
          },
          owner: {
            id: get(visit, 'agricultor_id', ''),
            name: get(visit, 'nome_agricultor', ''),
            identity: get(visit, 'cpf_cnpj_agricultor', ''),
          },
          city: {
            id: get(visit, 'idcidade', ''),
            name: get(visit, 'attributes.municipio', ''),
          },
          state: {
            id: get(visit, 'idestado', ''),
            name: get(visit, 'attributes.estado', ''),
          },
          harvest: {
            id: visit.safra_id,
            name: visit.ano_safra,
          },
          output: Number(visit.produtividade_ton),
          date: visit.created_at,
          area: {
            conventional: Number(get(visit, 'attributes.area_convencional', 0)),
            ipro: Number(get(visit, 'attributes.area_intacta', 0)),
          },
          informedArea: {
            conventional: get(visit, 'area_conv_informada', 0),
            ipro: get(visit, 'area_ipro_informada', 0),
          },
          kuhlmannGeoJson: kmljson,
          carGeoJson: carjson,
          silos: get(visit, 'attributes.silos', []).map((silo) => ({
            latitude: Number(silo.latitude),
            longitude: Number(silo.longitude),
            volume: Number(silo.volume),
            farm: {
              id: visit.fazenda_id,
              name: visit.nm_fazenda,
            },
            owner: {
              id: visit.agricultor_id,
              name: visit.nome_agricultor,
            },
          })),
          fields: get(visit, 'attributes.talhoes', []).map((field) => {
            const harvestOutput = get(
              find(outputResult, { ano_safra: visit.ano_safra, estado_id: visit.idestado }),
              'produtividade_kg_ha',
              0,
            );

            return ({
              id: get(field, 'id_talhao', ''),
              name: get(field, 'talhao', ''),
              tech: get(field, 'resultado.tecnologia', ''),
              farm: {
                id: get(visit, 'fazenda_id', ''),
                name: get(visit, 'nm_fazenda', ''),
              },
              district: {
                name: get(visit, 'nm_distrito', ''),
              },
              latitude: get(field, 'resultado.latitude', ''),
              longitude: get(field, 'resultado.longitude', ''),
              harvest: get(visit, 'ano_safra', ''),
              space: get(field, 'resultado.espacamento_semeio', 0),
              plantsM2: get(field, 'resultado.planta_metro_quadrado', 0),
              plantsStand: get(field, 'resultado.stand', 0),
              area: get(field, 'resultado.area', 0),
              owner: {
                name: get(visit, 'nome_agricultor', ''),
              },
              output: multiply(
                harvestOutput,
                get(field, 'resultado.area', 0),
              ),
            });
          }),
        });
      });

      const output = outputResult.map(({
        estado_id: stateId,
        ano_safra: harvest,
        produtividade_kg_ha: harvestOutput,
      }) => ({
        harvest,
        output: harvestOutput,
        stateId,
      }));

      const weather = weatherResult
        .map((weatherItem) => ({
          date: weatherItem.periodo,
          precipitation: Number(weatherItem.precipitacao_total),
          temperature: Number(weatherItem.temperatura_ar),
          city: {
            id: weatherItem.idcidade,
          },
        })).sort((a, b) => moment
          .utc(a.date).diff(moment.utc(b.date))).filter(({ date }) => moment().isAfter(date));

      return {
        visits,
        weather,
        output,
      };
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
  getResultsKMLs: (resultIds = []) => {
    try {
      const { request, cancel } = API.post('/koogle/endpoint', {
        action: 'getKmlResultados',
        params: {
          resultados_ids: Array.isArray(resultIds) ? resultIds : [resultIds],
        },
      });

      return {
        kmlsRequest: request
          .then(({ kml: resultKMLs }) => resultKMLs
            .map(({ resultado_id: id, kml }) => ({
              kml: `${process.env.REACT_APP_KML_URL}/${kml}`,
              id,
            }))),
        cancel,
      };
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
  getFarmReport: (farmId) => {
    try {
      const { request, cancel } = API.post('/koogle/endpoint', {
        action: 'getAreaLaudo',
        params: {
          fazenda_id: farmId,
        },
      });

      return {
        request: request.then((result) => {
          const report = get(result, 'laudo', '#') === '#' ? null : get(result, 'laudo', '#');

          if (report) {
            return `data:application/octet-stream;base64,${report}`;
          }

          return null;
        }),
        cancel,
      };
    } catch ({ message }) {
      Notify.error(message);

      return [];
    }
  },
};

export default Koogle;
