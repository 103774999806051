import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Auth } from '../services';

const useAuth = () => {
  const token = useSelector(({ auth }) => auth.token);
  const [isLogged, setIsLogged] = useState(!!token);
  const user = useSelector(({ userReducer }) => userReducer);

  const logout = useCallback(() => {
    Auth.logout();
  }, []);

  useEffect(() => {
    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [token]);

  return {
    isLogged,
    logout,
    token,
    user,
  };
};

export default useAuth;
