import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  find, get, groupBy, mapValues, uniq,
} from 'lodash';
import { useTheme } from '@material-ui/styles';
import moment from 'moment';

import {
  BarChart,
  CombinationChart,
  FarmInfoWindow,
  Map, Section, Table,
} from '../../../../components';
import useStyles from './styles';
import GraphSection from '../GraphSection';
import {
  divide, multiply, sum, ruleOfThree,
} from '../../../../helpers/math';
import {
  format,
} from '../../../../helpers/format';
import {
  DISTRICT_REFERENCE, FARM_REFERENCE, NUMERIC_FORMAT, OWNER_REFERENCE, REGION_REFERENCE,
} from '../../../../constants';
import { VISION_PARAMETERS } from './helpers';
import { removeDuplicates } from '../../../../helpers/array';

const Graphs = ({
  data, vision,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [fields, setFields] = useState([]);
  const [conventionalAreaXIpro, setconventionalAreaXIpro] = useState([]);
  const [plantsStand, setPlantsStand] = useState([]);
  const [penetrationHistory, setPenetrationHistory] = useState([]);
  const [weatherAndRain, setWeatherAndRain] = useState([]);
  const [conventionalAreaXIproOutput, setconventionalAreaXIproOutput] = useState([]);
  const [CONABXInformedOutput, setCONABXInformedOutput] = useState([]);

  const PARAM_TO_GROUP_BY = get(VISION_PARAMETERS, `${vision}.PARAM_TO_GROUP_BY`, '');

  const handleFields = useCallback(() => {
    const visits = get(data, 'visits', []);

    const newFields = visits
      .sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)))
      .reduce((previous, current) => [
        ...previous,
        ...get(current, 'fields', []),
      ], []);

    setFields(newFields);
  }, [data]);

  const handleConventionalAreaGraphData = useCallback(() => {
    const visits = get(data, 'visits', []);

    const groupedByHarvest = groupBy(visits, 'harvest.name');

    const harvestGraphs = Object.keys(groupedByHarvest).map((harvest) => {
      const visitOnHarvest = groupedByHarvest[harvest];

      const groupedByVision = groupBy(visitOnHarvest, PARAM_TO_GROUP_BY);

      const graphs = Object.keys(groupedByVision).map((label) => {
        const visionVisits = get(groupedByVision, label, []);

        return {
          label,
          values: {
            IPRO: Number(visionVisits
              .reduce((previous, current) => previous + get(
                current,
                'area.ipro',
                0,
              ),
              0)),
            'RR/Convencional': Number(visionVisits
              .reduce((previous, current) => previous + get(current,
                'area.conventional',
                0),
              0)),
          },
        };
      });

      return {
        harvest,
        graphs,
      };
    });

    setconventionalAreaXIpro(harvestGraphs);
  }, [data, PARAM_TO_GROUP_BY]);

  const handlePlantsStandGraphData = useCallback(() => {
    const allHarvests = get(data, 'visits', []).reduce((previous, current) => [
      ...previous,
      ...get(current, 'fields', []),
    ], []);

    const allHarvests2 = uniq(allHarvests.map(({ harvest }) => harvest));
    const groupedByVision = groupBy(allHarvests, PARAM_TO_GROUP_BY);

    const graphData = allHarvests2.map((harvest) => ({
      harvest,
      graphs: Object.keys(groupedByVision).map((label) => {
        const fieldsToReduce2 = groupedByVision[label].filter((field) => field.harvest === harvest);

        const IPROFields = fieldsToReduce2
          .filter(({ tech }) => tech === 'IPRO');

        const conventionalFields = fieldsToReduce2
          .filter(({ tech }) => tech === 'RR/Conv.');

        const {
          fieldPlantsStand: fieldPlantsStandIPRO,
          fieldArea: fieldAreaIPRO,
        } = IPROFields
          .reduce((previous, current) => ({
            fieldPlantsStand: sum(
              get(previous, 'fieldPlantsStand', 0),
              multiply(get(current, 'area', 0), get(current, 'plantsM2', 0)),
            ),
            fieldArea: sum(
              get(previous, 'fieldArea', 0),
              get(current, 'area', 0),
            ),
          }), {
            fieldPlantsStand: 0,
            fieldArea: 0,
          });

        const {
          fieldPlantsStand: fieldPlantsStandConventional,
          fieldArea: fieldAreaConventional,
        } = conventionalFields
          .reduce((previous, current) => ({
            fieldPlantsStand: sum(
              get(previous, 'fieldPlantsStand', 0),
              multiply(get(current, 'area', 0), get(current, 'plantsM2', 0)),
            ),
            fieldArea: sum(
              get(previous, 'fieldArea', 0),
              get(current, 'area', 0),
            ),
          }), {
            fieldPlantsStand: 0,
            fieldArea: 0,
          });

        return {
          label,
          values: {
            IPRO: divide(fieldPlantsStandIPRO, fieldAreaIPRO),
            'RR/Convencional': divide(fieldPlantsStandConventional, fieldAreaConventional),
          },
        };
      }),
    }));

    setPlantsStand(graphData);
  }, [data, PARAM_TO_GROUP_BY]);

  const handlePenetrationHistoryGraphData = useCallback(() => {
    const allFields = get(data, 'visits', [])
      .reduce((previous, current) => [
        ...previous,
        ...get(current, 'fields', []).filter(({ tech }) => tech),
      ], []);

    const groupedBy = groupBy(allFields, PARAM_TO_GROUP_BY);

    const actualPenetrationHistory = Object.keys(groupedBy).map((label) => {
      const values = mapValues(
        groupBy(groupedBy[label], 'harvest'),
        (fieldsToReduce) => {
          const iproArea = fieldsToReduce
            .filter(({ tech }) => tech === 'IPRO')
            .reduce((previous, current) => sum(previous, current.area), 0);

          const totalArea = fieldsToReduce
            .reduce((previous, current) => sum(previous, current.area), 0);

          return multiply(divide(iproArea, totalArea), 100);
        },
      );

      return {
        label,
        values,
      };
    });

    setPenetrationHistory(actualPenetrationHistory);
  }, [data, PARAM_TO_GROUP_BY]);

  const handleWeatherGraphData = useCallback(() => {
    const cities = get(data, 'visits', []).map(({ city }) => city.id);

    const averagedMonths = mapValues(groupBy(
      get(data, 'weather', []).filter((weatherItem) => cities.includes(weatherItem.city.id)), 'date',
    ), (weathers) => {
      const precipitation = divide(
        weathers.reduce((previous, current) => sum(previous, current.precipitation), 0),
        weathers.length,
      );

      const temperature = divide(
        weathers.reduce((previous, current) => sum(previous, current.temperature), 0),
        weathers.length,
      );

      return {
        precipitation,
        temperature,
      };
    });

    const formattedWeatherAndRain = Object.keys(averagedMonths).map((month) => ({
      x: moment(month).format('MMM/YYYY'),
      line: averagedMonths[month].temperature,
      bar: averagedMonths[month].precipitation,
    }));

    setWeatherAndRain(formattedWeatherAndRain);
  }, [data]);

  const handleOutputGraphData = useCallback(() => {
    const output = get(data, 'output', []);
    const visits = get(data, 'visits', []);

    const groupedByHarvest = groupBy(visits, 'harvest.name');

    const harvestGraphs = Object.keys(groupedByHarvest).map((harvest) => {
      const harvestVisits = groupedByHarvest[harvest];

      const groupedByVision = groupBy(harvestVisits, PARAM_TO_GROUP_BY);

      const graphs = Object.keys(groupedByVision).map((label) => {
        const totalArea = groupedByVision[label].reduce((previous, current) => sum(
          previous,
          get(current, 'area.ipro', 0),
          get(current, 'area.conventional', 0),
        ), 0);

        const iproArea = groupedByVision[label].reduce((previous, current) => sum(previous, get(current, 'area.ipro', 0)), 0);

        const conventionalArea = groupedByVision[label].reduce((previous, current) => sum(previous, get(current, 'area.conventional', 0)), 0);

        const stateId = get(groupedByVision[label], '[0].state.id', '');

        const stateOutput = get(
          find(output, {
            stateId,
            harvest,
          }),
          'output',
          0,
        );

        return {
          label,
          values: {
            IPRO: ruleOfThree(totalArea, stateOutput, iproArea),
            'RR/Convencional': ruleOfThree(totalArea, stateOutput, conventionalArea),
          },
        };
      });

      return {
        harvest,
        graphs,
      };
    });

    setconventionalAreaXIproOutput(harvestGraphs);
  }, [data, PARAM_TO_GROUP_BY]);

  const handleInformedXConabOutputGraphData = useCallback(() => {
    const havestToShow = '2020/2021';

    const havestVisits = removeDuplicates(
      get(data, 'visits', []).filter(({ harvest }) => harvest.name === havestToShow),
      { key: 'owner.id' },
    );
    const harvestCONABOutputs = get(data, 'output', [])
      .filter(({ harvest }) => harvest === havestToShow);

    const groupedByOwner = groupBy(havestVisits, 'owner.name');

    const graphData = Object.entries(groupedByOwner).map(([label, value]) => {
      const CONAB = Number(get(find(harvestCONABOutputs, {
        stateId: value[0].state.id,
      }), 'output', 0));

      return {
        label,
        values: {
          CONAB,
          Informada: value[0].output,
        },
      };
    });

    setCONABXInformedOutput([
      {
        harvest: havestToShow,
        graphs: graphData,
      },
    ]);
  }, [data]);

  useEffect(() => {
    handlePenetrationHistoryGraphData();
  }, [fields, handlePlantsStandGraphData, handlePenetrationHistoryGraphData]);

  useEffect(() => {
    handleFields();
    handleConventionalAreaGraphData();
    handleWeatherGraphData();
    handleOutputGraphData();
    handlePlantsStandGraphData();
    handleInformedXConabOutputGraphData();
  },
  [
    data, handleConventionalAreaGraphData,
    handlePlantsStandGraphData,
    handleFields, handleWeatherGraphData, handleOutputGraphData,
    handleInformedXConabOutputGraphData,
  ]);

  return (
    <div className={classes.container}>
      <div className={classes.graphsContainer}>
        <div className={classes.row}>
          <GraphSection
            title="Clima x Pluviosidade"
          >
            <div className={classes.graphContainer}>
              <CombinationChart
                showHint
                barAxisTitle="(mm)"
                lineAxisTitle="(°C)"
                lineColor={theme.palette.error.main}
                data={{
                  label: 'A',
                  values: weatherAndRain,
                }}
              />
            </div>
          </GraphSection>
          <div className={classnames(classes.flex1, classes.sectionContainer)}>
            <Section className={classes.flex1}>
              <Map
                style={{
                  borderRadius: 4,
                }}
                initialCenter={{
                  lat: -13.5409218,
                  lng: -58.1019205,
                }}
                zoom={10.25}
                pin={get(data, 'visits', []).reduce((previous, current) => {
                  const silos = get(current, 'silos', []).map((silo) => ({
                    coords: {
                      lat: silo.latitude,
                      lng: silo.longitude,
                    },
                    data: [
                      {
                        title: 'Latitude',
                        value: silo.latitude,
                      },
                      {
                        title: 'Longitude',
                        value: silo.longitude,
                      },
                      {
                        title: 'Capacidade Estática',
                        value: `${format(divide(silo.volume, 1000))} T`,
                      },
                      {
                        title: 'Fazenda',
                        value: silo.farm.name,
                      },
                      {
                        title: 'Produtor',
                        value: silo.owner.name,
                      },
                    ],
                  }));

                  return [
                    ...previous,
                    ...silos,
                  ];
                }, [])}
                geoJson={get(data, 'visits', []).reduce((previous, visit) => {
                  const renderItem = (
                    <FarmInfoWindow
                      regionId={visit.region.id}
                      districtId={visit.district.id}
                      ownerId={visit.owner.id}
                      farmId={visit.farm.id}
                      ownerName={visit.owner.name}
                      farmName={visit.farm.name}
                      cityState={`${visit.city.name}/${visit.state.name}`}
                      informedArea={`${format(
                        sum(visit.informedArea.conventional, visit.informedArea.ipro),
                      )} ha`}
                      auditedArea={`${format(
                        sum(visit.area.conventional, visit.area.ipro),
                      )} ha`}
                      iproPenetration={`${format(
                        ruleOfThree(
                          sum(visit.area.conventional, visit.area.ipro),
                          100,
                          visit.area.ipro,
                        ),
                      )}%`}
                    />
                  );

                  return [
                    ...previous,
                    {
                      options: {
                        fillColor: get(
                          theme.palette.geoJson.harvest,
                          visit.harvest.name,
                          theme.palette.geoJson.main,
                        ),
                        fillOpacity: 0.4,
                        strokeColor: get(
                          theme.palette.geoJson.harvest,
                          visit.harvest.name,
                          theme.palette.geoJson.main,
                        ),
                        strokeOpacity: 1,
                        strokeWeight: 1,
                      },
                      geoJson: visit.kuhlmannGeoJson,
                      renderItem,
                      legend: {
                        color: get(
                          theme.palette.geoJson.harvest,
                          visit.harvest.name,
                          theme.palette.geoJson.main,
                        ),
                        label: visit.harvest.name,
                      },
                    }, {
                      legend: {
                        color: theme.palette.geoJson.secondary,
                        label: 'CAR',
                      },
                      options: {
                        fillOpacity: 0,
                        strokeColor: theme.palette.geoJson.secondary,
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      },
                      geoJson: visit.carGeoJson,
                      renderItem,
                    },
                  ];
                }, []).filter(({ geoJson }) => geoJson)}
              />
            </Section>
          </div>
        </div>
        <GraphSection
          title="Estande de Plantas (Plantas/m²)"
        >
          <div className={classes.graphContainer}>
            <div className={classnames(classes.flex1, classes.row)}>
              {
                plantsStand.map((harvestGraphData, index) => (
                  <div key={index.toString()} className={classes.graphContainer}>
                    <div className={classes.graphHeaderContainer}>
                      {harvestGraphData.harvest}
                      <div className={classes.graphHeader} />
                    </div>
                    <BarChart
                      showHint
                      showLegend
                      data={harvestGraphData.graphs}
                      legendStyle={{ position: 'absolute', bottom: -35, maxWidth: '100%' }}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </GraphSection>
        <GraphSection
          title="Histórico Área Convencional x IPRO (ha)"
        >
          <div className={classes.graphContainer}>
            <div className={classnames(classes.flex1, classes.row)}>
              {
                conventionalAreaXIpro.map((harvestGraphData, index) => (
                  <div key={index.toString()} className={classes.graphContainer}>
                    <div className={classes.graphHeaderContainer}>
                      {harvestGraphData.harvest}
                      <div className={classes.graphHeader} />
                    </div>
                    <BarChart
                      showHint
                      showLegend
                      data={harvestGraphData.graphs}
                      legendStyle={{ position: 'absolute', bottom: -35, maxWidth: '100%' }}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </GraphSection>
        <GraphSection
          title="Histórico Produção Convencional x IPRO (t)*"
        >
          <div className={classnames(classes.flex1, classes.row)}>
            {
              conventionalAreaXIproOutput.map((harvestGraphs, index) => (
                <div key={index.toString()} className={classes.graphContainer}>
                  <div className={classes.graphHeaderContainer}>
                    {harvestGraphs.harvest}
                    <div className={classes.graphHeader} />
                  </div>
                  <BarChart
                    showHint
                    data={harvestGraphs.graphs}
                    showLegend
                    legendStyle={{ position: 'absolute', bottom: -35, maxWidth: '100%' }}
                  />
                </div>
              ))
            }
          </div>
          <div className={classes.graphSubTitle}>
            *Estimativa de Produção (t) por talhão calculada com base na estimativa
            de Produtividade Estadual da CONAB (t/ha), atualizada em Dezembro/2020.
          </div>
        </GraphSection>
        {
          vision === DISTRICT_REFERENCE
        && (
        <GraphSection
          title="Histórico Produtividade média CONAB x Informada (t/ha)"
        >
          <div className={classnames(classes.flex1, classes.row)}>
            {
              CONABXInformedOutput.map((harvestGraphs, index) => (
                <div key={index.toString()} className={classes.graphContainer}>
                  <div className={classes.graphHeaderContainer}>
                    {harvestGraphs.harvest}
                    <div className={classes.graphHeader} />
                  </div>
                  <BarChart
                    showHint
                    data={harvestGraphs.graphs}
                    showLegend
                    legendStyle={{ position: 'absolute', bottom: -35, maxWidth: '100%' }}
                  />
                </div>
              ))
            }
          </div>
          <div className={classes.graphSubTitle}>
            *Estimativa produtividade média estadual da CONAB (dezembro/2020) e
            Produtividade média informada pelo Produtor, através do Disk Intacta Bayer.
          </div>
        </GraphSection>
        )
}
        <GraphSection
          title="Histórico Penetração (%)"
        >
          <div className={classes.graphContainer}>
            <BarChart
              showHint
              horizontal
              xAxisValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              data={penetrationHistory}
              showLegend
              legendStyle={{ position: 'absolute', right: '50px', top: -20 }}
            />
          </div>
        </GraphSection>
      </div>
      <div className={classes.tableContainer}>
        <Table
          exportSheetName="Talhões"
          filterBy={[0, 1, 2, 3, 4]}
          columns={[
            'Talhão',
            'Fazenda',
            'Produtor',
            'Safra',
            'Tecnologia',
            'Produção (t)',
            'Latitude talhão',
            'Longitude talhão',
            'Área total talhão (ha)',
            'Plantas/m²',
            'Estande de plantas (Plantas/m)',
            'Espaçamento (cm)',
          ]}
          items={fields}
          getValues={(item) => ([
            get(item, 'name', ''),
            get(item, 'farm.name', ''),
            get(item, 'owner.name', ''),
            get(item, 'harvest', ''),
            get(item, 'tech', ''),
            format(item.output, NUMERIC_FORMAT),
            get(item, 'latitude', ''),
            get(item, 'longitude', ''),
            format(item.area, NUMERIC_FORMAT),
            format(item.plantsM2, NUMERIC_FORMAT),
            format(item.plantsStand, NUMERIC_FORMAT),
            format(item.space, NUMERIC_FORMAT),
          ])}
        />
      </div>
    </div>
  );
};

Graphs.defaultProps = {
  data: {
    visits: [],
    output: [],
    weather: [],
  },
};

Graphs.propTypes = {
  data: PropTypes.shape({
    visits: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    output: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    weather: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }),
  vision: PropTypes.oneOf([
    REGION_REFERENCE,
    DISTRICT_REFERENCE,
    FARM_REFERENCE,
    OWNER_REFERENCE,
  ]).isRequired,
};

export default Graphs;
