import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import useStyles from './styles';

const Autocomplete = ({
  label, placeholder, id, onChange, options, value,
  multiple, readOnly,
}) => {
  const classes = useStyles();

  return (
    <>
      {label && <Form.Label className={classes.label}>{label}</Form.Label> }
      <Typeahead
        disabled={readOnly}
        id={id}
        labelKey="label"
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        selected={!multiple
          ? options.filter((option) => option.id === value)
          : options.filter((option) => (Array.isArray(value) ? value.includes(option.id) : null))}
        paginationText="Ver mais..."
        emptyLabel="Não encontrado."
        multiple={multiple}
      />
    </>
  );
};

Autocomplete.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  id: uniqueId(),
  value: null,
  multiple: false,
  readOnly: false,
};

Autocomplete.propTypes = {
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
};

export default Autocomplete;
