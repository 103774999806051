export const messages = {
  INTERNAL_ERROR: 'Erro interno, por favor tente novamente mais tarde.',
  USER_NOT_LOGGED_IN: 'Você precisa estar logado para acessar esta página.',
};

export const catchAPIError = ({ status, message } = {}) => {
  if (status === 'error') {
    return { message: message || messages.INTERNAL_ERROR };
  }

  return false;
};
