import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Button = ({
  children, linkTo, type, onClick,
}) => (
  <BootstrapButton type={type} variant="primary" href={linkTo} onClick={onClick}>{children}</BootstrapButton>
);

Button.defaultProps = {
  linkTo: '',
  type: 'button',
  onClick: () => {},
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  linkTo: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
