import { formatLocale } from 'd3-format';

import {
  NUMERIC_FORMAT, CURRENCY_SYMBOL, DECIMAL_SEPARATOR, THOUSANDS_SEPARATOR,
} from '../constants';

export const format = (value = 0, pattern = NUMERIC_FORMAT) => {
  if (pattern === NUMERIC_FORMAT) {
    return parseFloat(value || 0).toLocaleString('pt-BR', { maximumFractionDigits: 3 });
  }

  return value;
};

const { format: chartAxisFormat } = formatLocale({
  decimal: DECIMAL_SEPARATOR,
  thousands: THOUSANDS_SEPARATOR,
  currency: CURRENCY_SYMBOL,
});

export const chartAxisFormatter = (value) => chartAxisFormat('.2s')(value);
