import React from 'react';
import { InfoWindow as RGPInfoWindow } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const InfoWindow = ({ position, onCloseClick, children }) => (
  <RGPInfoWindow
    position={position}
    onCloseClick={onCloseClick}
  >
    {children}
  </RGPInfoWindow>
);

InfoWindow.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  onCloseClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfoWindow;
