import { makeStyles } from '@material-ui/core';

import { hexToRgb } from '../../../../helpers/style';
import logoKuhlmann from '../../../../assets/LOGO_KTMA_Cinza.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    borderRadius: 20,
    border: '1px solid #C7C7C766',
    boxShadow: '0px 6px 10px #00000029',
    margin: '5px 0px',
  },
  imageContainerHover: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    display: ({ image }) => (image ? 'flex' : 'none'),
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb('#373531', { prefix: false })}, 0.5)`,
    },
  },
  loadingContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  imageContainer: {
    width: 115,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundImage: ({ image, isLoadingImage }) => (!isLoadingImage ? `url(${image}), url(${logoKuhlmann})` : null),
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: ({ image }) => (image ? 'cover' : 'contain'),
    boxShadow: '0px 6px 10px #00000029',
    borderRight: '1px solid #C7C7C766',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: ({ image }) => (image ? 'pointer' : null),
  },
  dataContainer: {
    display: 'flex',
    flex: 1,
    padding: '10px 25px',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0px',
  },
  label: {
    fontWeight: 'normal',
    fontSize: 14,
  },
  data: {
    fontWeight: 'bold',
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loadingFarmImageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardColumn: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  cardEyeBlock: {
    width: 25,
    paddingTop: 10,
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dashboardLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  menuContainer: {
    display: 'flex',
  },
  dashboardSpan: {
    marginRight: 5,
  },
}));

export default useStyles;
