import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';
import { ButtonSelect, LoadingWrapper } from '../../../../components';

const SearchBar = ({
  onDistrictChange, onRegionChange, regions, isLoading,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoadingWrapper isLoading={isLoading}>
        {
        regions.map(({ districts, label, id }, index) => (
          <ButtonSelect
            key={index.toString()}
            data={districts}
            label={label}
            onRootDoubleClick={() => onRegionChange({ id, label })}
            onChange={onDistrictChange}
          />
        ))
      }
      </LoadingWrapper>
    </div>
  );
};

SearchBar.defaultProps = {
  regions: [],
  isLoading: false,
};

SearchBar.propTypes = {
  onDistrictChange: PropTypes.func.isRequired,
  onRegionChange: PropTypes.func.isRequired,
  regions: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
};

export default SearchBar;
