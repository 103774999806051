import React from 'react';
import PropTypes from 'prop-types';

import Topbar from '../Topbar';

const Template = ({ children }) => (
  <>
    <Topbar />
    {children}
  </>
);

Template.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Template;
