import React from 'react';
import { Carousel as BaseCarousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Carousel = ({
  items, emptyLabel, width, onClickItem,
}) => {
  const classes = useStyles();

  return items.length ? (
    <BaseCarousel
      showArrows
      showStatus={false}
      showThumbs={false}
      width={width}
      onClickItem={onClickItem}
    >
      {
        items.map(({ image, label }) => (
          <div key={image.toString()}>
            <img src={image} alt="" />
            {label && <p className="legend">{label}</p>}
          </div>
        ))
    }
    </BaseCarousel>
  ) : (
    <div className={classes.emptyContainer}>
      {emptyLabel}
    </div>
  );
};

Carousel.defaultProps = {
  items: [],
  emptyLabel: 'Nenhuma imagem encontrada.',
  width: null,
  onClickItem: () => {},
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired),
  emptyLabel: PropTypes.string,
  width: PropTypes.number,
  onClickItem: PropTypes.func,
};

export default Carousel;
