import { useCallback, useState } from 'react';

const useGraphItemOpacity = () => {
  const [focusedItem, setFocusedItem] = useState(null);

  const handleFocusedItemChange = useCallback(({ title }) => {
    setFocusedItem(focusedItem === title ? null : title);
  }, [focusedItem]);

  const getItemOpacity = useCallback((label) => {
    if (!focusedItem) {
      return 1;
    }

    return label === focusedItem ? 1 : 0.15;
  }, [focusedItem]);

  return {
    handleFocusedItemChange,
    getItemOpacity,
  };
};

export default useGraphItemOpacity;
