import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { get, orderBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import { stringify } from 'query-string';
import moment from 'moment';

import { LoadingWrapper, Template } from '../../components';
import {
  SearchBar, HomeMap, List, Filters,
} from './components';
import useStyles from './styles';
import { Koogle } from '../../services';
import { removeDuplicates } from '../../helpers/array';

const Home = () => {
  const classes = useStyles();
  const location = useLocation();

  const [filters, setFilters] = useState({
    district: [],
    region: [],
    harvest: [],
    state: [],
    city: [],
    owner: [],
    identity: [],
    farm: [],
  });
  const [showFullMap, setShowFullMap] = useState(false);
  const [homeResultCache, setHomeResultCache] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [homeResult, setHomeResult] = useState([]);
  const [isLoadingRegions, setIsLoadingRegions] = useState(true);
  const [regionsToFilter, setRegionsToFilter] = useState([]);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleDistrictFilterChange = useCallback((district) => {
    let newDistricts = [
      ...get(filters, 'district', []),
      district,
    ];

    newDistricts = removeDuplicates(newDistricts, { key: 'id' });

    setFilters({
      ...filters,
      district: newDistricts,
    });
  }, [filters]);

  const handleRegionFilterChange = useCallback((region) => {
    let newRegions = [
      ...get(filters, 'region', []),
      region,
    ];

    newRegions = removeDuplicates(newRegions, { key: 'id' });

    setFilters({
      ...filters,
      region: newRegions,
    });
  }, [filters]);

  const handleChange = useCallback((changedFilter) => {
    const newFilter = {};

    Object.keys(changedFilter).forEach((item) => {
      newFilter[item] = removeDuplicates(changedFilter[item], { key: 'id' });
    });

    setFilters(newFilter);
  }, []);

  const loadRegions = useCallback(async () => {
    setIsLoadingRegions(true);
    const {
      regions: resultRegions = [],
      harvests: resultHarvest = [],
    } = await Koogle.getRegionsAndHarvest();

    setRegionsToFilter(resultRegions
      .filter((region) => region.districts.length)
      .map(({ id, name: label, districts }) => ({
        id,
        label,
        districts: districts.map(({ name, id: districtId }) => ({
          id: districtId,
          label: name,
        })),
      })));

    const newHarvests = {};

    resultHarvest.forEach((harvest) => {
      newHarvests[harvest.id] = harvest.name;
    });

    setIsLoadingRegions(false);

    return {
      harvests: newHarvests,
    };
  }, []);

  const loadHomeResultCache = useCallback(async ({ regions = [], districts = [] }) => {
    setIsLoading(true);
    const { visits = [] } = await Koogle.getDashboardResults({ regions, districts });

    setHomeResultCache(visits);

    setIsLoading(false);
  }, []);

  const refreshURLState = useCallback((filtersToPushState) => {
    const parsedFilter = Object.keys(filtersToPushState).reduce((previous, current) => ({
      ...previous,
      [current]: filtersToPushState[current].map((filter) => filter.id),
    }), {});

    const URLQuery = stringify(parsedFilter);

    window.history.pushState({}, null,
      URLQuery ? `${location.pathname}?${URLQuery}` : location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    let filtersCount = 0;

    Object.keys(filters).forEach((filterKey) => {
      filtersCount += filters[filterKey].length;
    });

    if (filtersCount && showFullMap) {
      setShowFullMap(false);
    }
  }, [filters, showFullMap]);

  useEffect(() => {
    const regions = filters.region.map((region) => region.id);
    const districts = filters.district.map((district) => district.id);
    const harvests = filters.harvest.map((harvest) => harvest.id);
    const states = filters.state.map((state) => state.id);
    const cities = filters.city.map((city) => city.id);
    const owners = filters.owner.map((owner) => owner.id);
    const indentities = filters.identity.map((identity) => identity.id);
    const farms = filters.farm.map((farm) => farm.id);

    const filteredResult = homeResultCache
      .filter((result) => (regions.length
        ? regions.includes(get(result, 'region.id', null))
        : true))
      .filter((result) => (districts.length
        ? districts.includes(get(result, 'district.id', null))
        : true))
      .filter((result) => (harvests.length
        ? harvests.includes(get(result, 'harvest.id', null))
        : true))
      .filter((result) => (states.length
        ? states.includes(get(result, 'state.id', null))
        : true))
      .filter((result) => (cities.length
        ? cities.includes(get(result, 'city.id', null))
        : true))
      .filter((result) => (owners.length
        ? owners.includes(get(result, 'owner.id', null))
        : true))
      .filter((result) => (indentities.length
        ? indentities.includes(get(result, 'owner.identity', null))
        : true))
      .filter((result) => (farms.length
        ? farms.includes(get(result, 'farm.id', null))
        : true));

    setHomeResult(orderBy(filteredResult, (result) => moment(result.date), ['desc']));
  }, [filters, homeResultCache]);

  useEffect(() => {
    if (
      get(filters, 'region', []).length
      || get(filters, 'district', []).length
      || isContentVisible
    ) {
      setIsContentVisible(true);

      loadHomeResultCache({
        regions: get(filters, 'region', []).map(({ id }) => id),
        districts: get(filters, 'district', []).map(({ id }) => id),
      });
    }
  }, [filters, loadHomeResultCache, isContentVisible]);

  useEffect(() => {
    refreshURLState(filters);
  }, [filters, refreshURLState]);

  useEffect(() => {
    loadRegions();
  }, [loadRegions]);

  return (
    <Template>
      <SearchBar
        onDistrictChange={handleDistrictFilterChange}
        onRegionChange={handleRegionFilterChange}
        regions={regionsToFilter}
        isLoading={isLoadingRegions}
      />
      <div className={classnames({
        [classes.container]: true,
        [classes.center]: isLoading,
      })}
      >
        {
          isContentVisible ? (
            <LoadingWrapper isLoading={isLoading}>
              <div
                className={classnames({
                  [classes.resultContainer]: true,
                  [classes.displayNone]: showFullMap,
                })}
              >
                <Filters
                  filters={filters}
                  onChange={handleChange}
                  data={homeResultCache}
                />
                <List
                  data={homeResult}
                />
              </div>
              <div className={classes.mapContainer}>
                <HomeMap
                  data={homeResult
                    .filter(({ carGeoJson, kuhlmannGeoJson }) => carGeoJson || kuhlmannGeoJson)}
                />
              </div>
            </LoadingWrapper>
          ) : (
            <span className={classes.filterToStartLabel}>
              Selecione uma regional ou um distrito.
            </span>
          )
        }
      </div>
    </Template>
  );
};

export default Home;
