import { get } from 'lodash';

export const removeDuplicates = (data, { key = 'id' } = {}) => {
  const collection = typeof data[0] === 'object';

  if (collection) {
    const newData = [];

    data.forEach((item) => {
      if (!newData.map((dataToCompare) => get(dataToCompare, key)).includes(get(item, key))) {
        newData.push(item);
      }
    });

    return newData;
  }

  return data.filter((item, index) => data.indexOf(item) === index);
};
