/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-vis/dist/style.css';
import { LoadScript } from '@react-google-maps/api';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { theme } from './styles';
import Routes from './routes';
import { store, persistor } from './store';

const originalWarn = console.warn.bind(console.warn);
console.warn = (msg) => !msg.toString().includes('componentWillReceiveProps') && !msg.toString().includes('findDOMNode is deprecated in StrictMode') && !msg.toString().includes('UNSAFE_componentWillReceiveProps') && originalWarn(msg);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </ThemeProvider>
        </LoadScript>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
