import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useStyles from './styles';
import logoBV from '../../assets/login_screen_BV_ico.svg';
import logoHarvest from '../../assets/logo-harvest.svg';
import logoKoogle from '../../assets/logo-koogle.svg';
import Button from '../Button';
import routes from '../../routes/routes';
import { useAuth } from '../../hooks';
import Icon from '../Icon';

const Topbar = () => {
  const classes = useStyles();
  const { logout } = useAuth();
  const history = useHistory();

  const showLogoutButton = useRouteMatch(Object.keys(routes).map((routeName) => routes[routeName])
    .filter((route) => route.private)
    .map(({ path }) => path)) !== null;

  const showBackButton = useRouteMatch(Object.keys(routes).map((routeName) => routes[routeName])
    .filter((route) => route.showBackButton)
    .map(({ path }) => path)) !== null;

  const handleGoBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {showBackButton && (
        <Icon
          name="faArrowLeft"
          size="1x"
          color="white"
          onClick={handleGoBackClick}
        />
        )}
        <div className={classes.logoContainer}>
          <img src={logoKoogle} className={classes.image} alt="Koogle Logo" />
          <div className={classes.bar} />
          <img src={logoHarvest} className={classnames(classes.image, classes.harvestImage)} alt="Harvest Logo" />
          <div className={classes.bar} />
          <img src={logoBV} className={classnames(classes.image, classes.BVImage)} alt="BV Logo" />
        </div>
        {showLogoutButton && <Button onClick={logout}>SAIR</Button>}
      </div>
    </div>
  );
};

export default Topbar;
