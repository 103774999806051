import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  modalTitle: {
    fontSize: 'unset',
    fontWeight: 'bold',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,
  },
});

export default useStyles;
