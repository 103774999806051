import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { LoadingWrapper, Template } from '../../components';
import { Koogle } from '../../services';
import { SearchBar, Graphs } from './components';
import useStyles from './styles';

const Farm = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [farm, setFarm] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadFarm = useCallback(async () => {
    setIsLoading(true);

    const resultFarm = await Koogle.getFarm(id);

    setFarm(resultFarm);
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    loadFarm();
  }, [id, loadFarm]);

  return (
    <Template>
      <SearchBar />
      <div className={classnames({
        [classes.root]: isLoading,
      })}
      >
        <LoadingWrapper isLoading={isLoading}>
          <Graphs farm={farm} />
        </LoadingWrapper>
      </div>
    </Template>
  );
};

export default Farm;
