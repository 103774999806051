import React, { useCallback, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import routes from '../routes';
import { useAuth } from '../../hooks';

const PublicRoute = (props) => {
  const history = useHistory();
  const { logout, isLogged } = useAuth();

  const handleRedirect = useCallback(() => {
    history.push(routes.home.path);
  }, [history]);

  useEffect(() => {
    if (isLogged) {
      return handleRedirect();
    }

    logout();
  }, [isLogged, handleRedirect, logout]);

  return (
    <Route {...props} />
  );
};

export default PublicRoute;
