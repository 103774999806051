import axios from 'axios';

import { get } from 'lodash';
import StatusCodes from 'http-status-codes';
import { store } from '../store';
import { catchAPIError, messages } from '../helpers/errors';
import { clearUser } from '../store/actions/user';
import { clearToken } from '../store/actions/auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  const newConfig = {
    ...config,
  };

  const state = store.getState();

  const token = get(state, 'auth.token', null);

  if (token) {
    newConfig.data = {
      ...newConfig.data,
      service: 'Koogle',
      token,
    };
  }

  return newConfig;
});

instance.interceptors.response.use((response) => {
  const error = catchAPIError(response.data);

  if (error) {
    return Promise.reject(error);
  }

  return response.data;
}, (error) => {
  if (get(error, 'response.status', StatusCodes.INTERNAL_SERVER_ERROR) === StatusCodes.UNAUTHORIZED) {
    store.dispatch(clearUser());
    store.dispatch(clearToken());

    return Promise.reject({ message: messages.USER_NOT_LOGGED_IN });
  }

  return Promise.reject({ message: messages.INTERNAL_ERROR });
});

export default {
  get: (route, params = {}) => instance.get(route, { params }),
  post: (route, body = {}) => {
    const { CancelToken } = axios;
    let cancel = () => {};

    const request = instance.post(route, body, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    });

    return {
      request,
      cancel,
    };
  },
  delete: (route, body = {}) => instance.delete(route, body),
  put: (route, body = {}) => instance.put(route, body),
};
