import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '10px 25px',
  },
  inputContainer: {
    display: 'flex',
    flex: 2,
    padding: '0px 5px',
    flexDirection: 'column',
  },
  miniInputContainer: {
    flex: 1,
  },
});

export default useStyles;
