import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import routes from '../routes';
import { useAuth } from '../../hooks';

const PrivateRoute = (props) => {
  const history = useHistory();
  const { isLogged, logout } = useAuth();

  useEffect(() => {
    if (!isLogged) {
      logout();
      history.push(routes.login.path);
    }
  }, [isLogged, history, logout]);

  return (
    <Route {...props} />
  );
};

export default PrivateRoute;
