import { get } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const MapLegend = ({
  filterSelected, items, mapInstance, onClickItem,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const map = mapInstance;

    if (map) {
      const legendElement = document.getElementById('mapLegend');

      map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM] = [
        legendElement,
      ];
    }
  }, [mapInstance]);

  return (
    <div id="mapLegend" className={classes.container}>
      {
        items.map((item, index) => (
          <div
            className={classes.legendRow}
            onClick={() => onClickItem(item)}
            key={index.toString()}
          >
            <div
              className={classes.legendIndicator}
              style={{
                backgroundColor: item.color,
              }}
            />
            <span
              className={classes.legendSpan}
              style={{
                fontWeight: get(filterSelected, 'color', '') === item.color
                  ? 'bold'
                  : 'normal',
              }}
            >
              {item.label}
            </span>
          </div>
        ))
    }
    </div>
  );
};

MapLegend.propTypes = {
  filterSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  mapInstance: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]).isRequired,
  onClickItem: PropTypes.func.isRequired,
};

export default MapLegend;
