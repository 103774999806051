import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useStyles from './styles';

const Icon = ({
  name, onClick, size, color,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classnames({ [classes.pointerOnHover]: typeof onClick === 'function' })}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={icons[name]}
        size={size}
        color={color}
      />
    </div>
  );
};

Icon.defaultProps = {
  onClick: null,
  size: '1x',
  color: '',
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Icon;
