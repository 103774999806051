import React, { useState } from 'react';
import {
  ChartLabel,
  FlexibleXYPlot, Hint, HorizontalGridLines,
  LineMarkSeries,
  VerticalBarSeries, VerticalGridLines, XAxis, YAxis,
} from 'react-vis';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';

import { handleAxisDivision, messages } from '../../helpers/chart';
import { chartAxisFormatter, format } from '../../helpers/format';
import { divide, multiply, sum } from '../../helpers/math';
import { theme as staticTheme } from '../../styles';
import useStyles from './styles';
import ChartHint from '../ChartHint';

const CombinationChart = ({
  data, barAxisTitle, lineAxisTitle, lineColor,
  showHint,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [hoveredNode, setHoveredNode] = useState(null);

  const maxXAxisLenght = data.values
    .map(({ x }) => x)
    .reduce((previous, current) => (previous < current.length ? current.length : previous), 0);

  const maxYLineAxisLenght = data.values
    .map(({ line }) => line)
    .reduce((previous, current) => (previous < chartAxisFormatter(current.toString()).length
      ? chartAxisFormatter(current.toString()).length
      : previous
    ), 0);

  const maxYBarAxisLenght = data.values
    .map(({ bar }) => bar)
    .reduce((previous, current) => (previous < chartAxisFormatter(current.toString()).length
      ? chartAxisFormatter(current.toString()).length
      : previous
    ), 0);

  const maxBarValue = data.values
    .map(({ bar }) => bar)
    .reduce((previous, current) => (previous < current ? current : previous), 0);

  const barAxisValues = handleAxisDivision(maxBarValue);

  const maxLineValue = data.values
    .map(({ line }) => line)
    .reduce((previous, current) => (previous < current ? current : previous), 0);

  const lineAxisValues = handleAxisDivision(maxLineValue);

  return get(data, 'values', []).length
    ? (
      <FlexibleXYPlot
        stackBy="y"
        xType="ordinal"
        onMouseLeave={() => setHoveredNode(null)}
        margin={{
          bottom: multiply(maxXAxisLenght, 10),
          right: multiply(maxYLineAxisLenght, 20),
          left: sum(multiply(maxYBarAxisLenght, 10), barAxisTitle ? 20 : 0),
        }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          tickLabelAngle={270}
        />
        <YAxis
          orientation="left"
          tickValues={barAxisValues}
          tickFormat={chartAxisFormatter}
        />
        {barAxisTitle && (
        <ChartLabel
          text={barAxisTitle}
          yPercent={0.125}
          xPercent={0.03}
          style={{
            transform: 'rotate(-90)',
            textAnchor: 'middle',
          }}
        />
        )}
        {lineAxisTitle && (
        <ChartLabel
          text={lineAxisTitle}
          yPercent={0.125}
          xPercent={0.998}
          style={{
            transform: 'rotate(-90)',
            textAnchor: 'middle',
          }}
        />
        )}
        <YAxis
          orientation="right"
          tickValues={barAxisValues}
          tickFormat={(_, index) => chartAxisFormatter(lineAxisValues[index])}
        />
        <VerticalBarSeries
          onValueMouseOver={(d) => setHoveredNode({
            ...d,
            value: d.y,
            ...data,
          })}
          cluster={data.label}
          data={data.values.map(({ x, bar }) => ({
            x,
            y: bar,
          }))}
          color={theme.palette.secondary.main}
        />
        <LineMarkSeries
          data={data.values.map(({ x, line }) => ({
            x,
            y: divide(
              multiply(
                Number(line),
                maxBarValue,
              ),
              maxLineValue,
            ),
          }))}
          color={lineColor}
          onValueMouseOver={(d) => {
            setHoveredNode({
              ...d,
              value: get(find(data.values, { x: d.x }), 'line', 0),
              ...data,
            });
          }}
        />
        {hoveredNode && showHint && (
        <Hint
          value={{
            x: hoveredNode.x,
            y: hoveredNode.y,
          }}
        >
          <ChartHint>
            {`${hoveredNode.x}: ${format(hoveredNode.value)}`}
          </ChartHint>
        </Hint>
        )}
      </FlexibleXYPlot>
    ) : (
      <div className={classes.emptyContaier}>
        {messages.EMPTY_DATA}
      </div>
    );
};

CombinationChart.defaultProps = {
  data: {},
  barAxisTitle: '',
  lineAxisTitle: '',
  lineColor: staticTheme.palette.secondary.dark,
  showHint: false,
};

CombinationChart.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      line: PropTypes.number.isRequired,
      bar: PropTypes.number.isRequired,
    })),
  }),
  barAxisTitle: PropTypes.string,
  lineAxisTitle: PropTypes.string,
  lineColor: PropTypes.string,
  showHint: PropTypes.bool,
};

export default CombinationChart;
