import { get } from 'lodash';
import { stringify } from 'query-string';

import routes from '../routes/routes';

export const makeRoute = (routeName, params = {}, search = {}) => {
  let route = get(routes[routeName], 'path', '');

  Object.keys(params).forEach((paramName) => {
    route = route.replace(`:${paramName}`, params[paramName]);
  });

  route = `${route}?${stringify(search)}`;

  return route;
};
