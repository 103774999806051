import { hexToRgb as MUIhexToRgb } from '@material-ui/core/styles/colorManipulator';

const hexToRgb = (hex, { prefix = true } = {}) => {
  if (prefix) {
    return MUIhexToRgb(hex);
  }

  return MUIhexToRgb(hex).replace('rgb(', '').replace(')', '');
};

export {
  hexToRgb,
};
