import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';

import useStyles from './styles';
import { Autocomplete, Chip } from '../../../../components';
import { removeDuplicates } from '../../../../helpers/array';

const Filters = ({ filters, onChange, data }) => {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState({
    city: [],
    state: [],
    owner: [],
    identity: [],
    farm: [],
    harvest: [],
  });

  useEffect(() => {
    const harvest = removeDuplicates(data
      .filter((item) => (filters.region.length
        ? filters.region
          .map((regionToCompare) => regionToCompare.id).includes(get(item, 'region.id', null))
        : true))
      .filter((item) => (filters.district.length
        ? filters.district
          .map((districtToCompare) => districtToCompare.id).includes(get(item, 'district.id', null))
        : true))
      .map((item) => ({
        id: get(item, 'harvest.id', null),
        label: get(item, 'harvest.name', null),
      }))
      .sort((a, b) => a.label > b.label));

    const state = removeDuplicates(data
      .filter((item) => (filters.region.length
        ? filters.region
          .map((regionToCompare) => regionToCompare.id).includes(get(item, 'region.id', null))
        : true))
      .filter((item) => (filters.district.length
        ? filters.district
          .map((districtToCompare) => districtToCompare.id).includes(get(item, 'district.id', null))
        : true))
      .filter((item) => (filters.harvest.length
        ? filters.harvest
          .map((harvestToCompare) => harvestToCompare.id).includes(get(item, 'harvest.id', null))
        : true))
      .map((item) => ({
        id: get(item, 'state.id', null),
        label: get(item, 'state.name', null),
      }))
      .sort((a, b) => a.label > b.label),
    { key: 'id' });

    const city = removeDuplicates(data
      .filter((item) => (filters.region.length
        ? filters.region
          .map((regionToCompare) => regionToCompare.id).includes(get(item, 'region.id', null))
        : true))
      .filter((item) => (filters.district.length
        ? filters.district
          .map((districtToCompare) => districtToCompare.id).includes(get(item, 'district.id', null))
        : true))
      .filter((item) => (filters.harvest.length
        ? filters.harvest
          .map((harvestToCompare) => harvestToCompare.id).includes(get(item, 'harvest.id', null))
        : true))
      .filter((item) => (filters.state.length
        ? filters.state
          .map((stateToCompare) => stateToCompare.id).includes(get(item, 'state.id', null))
        : true))
      .map((item) => ({
        id: get(item, 'city.id', null),
        label: get(item, 'city.name', null),
      }))
      .sort((a, b) => a.label > b.label),
    { key: 'id' });

    const owner = removeDuplicates(data
      .filter((item) => (filters.region.length
        ? filters.region
          .map((regionToCompare) => regionToCompare.id).includes(get(item, 'region.id', null))
        : true))
      .filter((item) => (filters.district.length
        ? filters.district
          .map((districtToCompare) => districtToCompare.id).includes(get(item, 'district.id', null))
        : true))
      .filter((item) => (filters.harvest.length
        ? filters.harvest
          .map((harvestToCompare) => harvestToCompare.id).includes(get(item, 'harvest.id', null))
        : true))
      .filter((item) => (filters.state.length
        ? filters.state
          .map((stateToCompare) => stateToCompare.id).includes(get(item, 'state.id', null))
        : true))
      .filter((item) => (filters.city.length
        ? filters.city
          .map((cityToCompare) => cityToCompare.id).includes(get(item, 'city.id', null))
        : true))
      .map((item) => ({
        id: get(item, 'owner.id', null),
        label: get(item, 'owner.name', null),
      }))
      .sort((a, b) => a.label > b.label),
    { key: 'id' });

    const identity = removeDuplicates(data
      .filter((item) => (filters.region.length
        ? filters.region
          .map((regionToCompare) => regionToCompare.id).includes(get(item, 'region.id', null))
        : true))
      .filter((item) => (filters.district.length
        ? filters.district
          .map((districtToCompare) => districtToCompare.id).includes(get(item, 'district.id', null))
        : true))
      .filter((item) => (filters.harvest.length
        ? filters.harvest
          .map((harvestToCompare) => harvestToCompare.id).includes(get(item, 'harvest.id', null))
        : true))
      .filter((item) => (filters.state.length
        ? filters.state
          .map((stateToCompare) => stateToCompare.id).includes(get(item, 'state.id', null))
        : true))
      .filter((item) => (filters.city.length
        ? filters.city
          .map((cityToCompare) => cityToCompare.id).includes(get(item, 'city.id', null))
        : true))
      .filter((item) => (filters.owner.length
        ? filters.owner
          .map((ownerToCompare) => ownerToCompare.id).includes(get(item, 'owner.id', null))
        : true))
      .map((item) => ({
        id: get(item, 'owner.identity', null),
        label: get(item, 'owner.identity', null),
      }))
      .sort((a, b) => a.label > b.label),
    { key: 'id' });

    const farm = removeDuplicates(data
      .filter((item) => (filters.region.length
        ? filters.region
          .map((regionToCompare) => regionToCompare.id).includes(get(item, 'region.id', null))
        : true))
      .filter((item) => (filters.district.length
        ? filters.district
          .map((districtToCompare) => districtToCompare.id).includes(get(item, 'district.id', null))
        : true))
      .filter((item) => (filters.harvest.length
        ? filters.harvest
          .map((harvestToCompare) => harvestToCompare.id).includes(get(item, 'harvest.id', null))
        : true))
      .filter((item) => (filters.state.length
        ? filters.state
          .map((stateToCompare) => stateToCompare.id).includes(get(item, 'state.id', null))
        : true))
      .filter((item) => (filters.city.length
        ? filters.city
          .map((cityToCompare) => cityToCompare.id).includes(get(item, 'city.id', null))
        : true))
      .filter((item) => (filters.owner.length
        ? filters.owner
          .map((ownerToCompare) => ownerToCompare.id).includes(get(item, 'owner.id', null))
        : true))
      .filter((item) => (filters.identity.length
        ? filters.identity
          .map((identityToCompare) => identityToCompare.id).includes(get(item, 'owner.identity', null))
        : true))
      .map((item) => ({
        id: get(item, 'farm.id', null),
        label: get(item, 'farm.name', null),
      }))
      .sort((a, b) => a.label > b.label),
    { key: 'id' });

    setFilterOptions({
      city,
      state,
      owner,
      identity,
      farm,
      harvest,
    });
  }, [data, filters]);

  return (
    <div className={classes.container}>
      <span className={classes.selectedFilterLabel}>Filtro Selecionado</span>
      <div className={classes.chipsContainer}>
        {[...get(filters, 'region', []), ...get(filters, 'district', [])].map((regionOrDistrict, index) => (
          <Chip
            key={index.toString()}
            label={regionOrDistrict.label}
            onDismiss={() => {
              onChange({
                ...filters,
                region: filters.region
                  .filter((actualRegion) => actualRegion.id !== regionOrDistrict.id),
                district: filters.district
                  .filter((actualDistrict) => actualDistrict.id !== regionOrDistrict.id),
              });
            }}
          />
        ))}
      </div>
      <div className={classes.inputContainer}>
        <Autocomplete
          label="Safra"
          options={filterOptions.harvest}
          onChange={(value) => {
            const newHavest = [
              ...filters.harvest,
              value[0],
            ];

            onChange({
              ...filters,
              harvest: newHavest
                .filter((actualHarvest, index) => newHavest.indexOf(actualHarvest) === index),
            });
          }}
        />
        <div
          className={classnames({
            [classes.filterChipsContainer]: true,
            [classes.displayNone]: !get(filters, 'harvest', []).length,
          })}
        >
          {get(filters, 'harvest', []).map((harvest, index) => (
            <Chip
              key={index.toString()}
              label={harvest.label}
              onDismiss={() => {
                onChange({
                  ...filters,
                  harvest: filters.harvest.filter((newHarvest) => newHarvest.id !== harvest.id),
                });
              }}
            />
          ))}
        </div>
      </div>
      <div className={classes.inputContainer}>
        <Autocomplete
          label="UF"
          options={filterOptions.state}
          onChange={(value) => {
            const newState = [
              ...filters.state,
              value[0],
            ];

            onChange({
              ...filters,
              state: newState
                .filter((actualState, index) => newState.indexOf(actualState) === index),
            });
          }}
        />
        <div
          className={classnames({
            [classes.filterChipsContainer]: true,
            [classes.displayNone]: !get(filters, 'state', []).length,
          })}
        >
          {get(filters, 'state', []).map((state, index) => (
            <Chip
              key={index.toString()}
              label={state.label}
              onDismiss={() => {
                onChange({
                  ...filters,
                  state: filters.state.filter((newState) => newState.id !== state.id),
                });
              }}
            />
          ))}
        </div>
      </div>
      <div className={classes.inputContainer}>
        <Autocomplete
          label="Município"
          options={filterOptions.city}
          onChange={(value) => {
            const newCity = [
              ...filters.city,
              value[0],
            ];

            onChange({
              ...filters,
              city: newCity
                .filter((actualCity, index) => newCity.indexOf(actualCity) === index),
            });
          }}
        />
        <div
          className={classnames({
            [classes.filterChipsContainer]: true,
            [classes.displayNone]: !get(filters, 'city', []).length,
          })}
        >
          {get(filters, 'city', []).map((city, index) => (
            <Chip
              key={index.toString()}
              label={city.label}
              onDismiss={() => {
                onChange({
                  ...filters,
                  city: filters.city.filter((newCity) => newCity.id !== city.id),
                });
              }}
            />
          ))}
        </div>
      </div>
      <div className={classes.inputContainer}>
        <Autocomplete
          label="Produtor"
          options={filterOptions.owner}
          onChange={(value) => {
            const newOwner = [
              ...filters.owner,
              value[0],
            ];

            onChange({
              ...filters,
              owner: newOwner
                .filter((actualOwner, index) => newOwner.indexOf(actualOwner) === index),
            });
          }}
        />
        <div
          className={classnames({
            [classes.filterChipsContainer]: true,
            [classes.displayNone]: !get(filters, 'owner', []).length,
          })}
        >
          {get(filters, 'owner', []).map((owner, index) => (
            <Chip
              key={index.toString()}
              label={owner.label}
              onDismiss={() => {
                onChange({
                  ...filters,
                  owner: filters.owner.filter((newOwner) => newOwner.id !== owner.id),
                });
              }}
            />
          ))}
        </div>
      </div>
      <div className={classes.inputContainer}>
        <Autocomplete
          label="CPF/CNPJ"
          options={filterOptions.identity}
          onChange={(value) => {
            const newIdentity = [
              ...filters.identity,
              value[0],
            ];

            onChange({
              ...filters,
              identity: newIdentity
                .filter((actualIdentity, index) => newIdentity.indexOf(actualIdentity) === index),
            });
          }}
        />
        <div
          className={classnames({
            [classes.filterChipsContainer]: true,
            [classes.displayNone]: !get(filters, 'identity', []).length,
          })}
        >
          {get(filters, 'identity', []).map((identity, index) => (
            <Chip
              key={index.toString()}
              label={identity.label}
              onDismiss={() => {
                onChange({
                  ...filters,
                  identity: filters.identity
                    .filter((newIdentity) => newIdentity.id !== identity.id),
                });
              }}
            />
          ))}
        </div>
      </div>
      <div className={classes.inputContainer}>
        <Autocomplete
          label="Fazenda"
          options={filterOptions.farm}
          onChange={(value) => {
            const newFarm = [
              ...filters.farm,
              value[0],
            ];

            onChange({
              ...filters,
              farm: newFarm
                .filter((actualFarm, index) => newFarm.indexOf(actualFarm) === index),
            });
          }}
        />
        <div
          className={classnames({
            [classes.filterChipsContainer]: true,
            [classes.displayNone]: !get(filters, 'farm', []).length,
          })}
        >
          {get(filters, 'farm', []).map((farm, index) => (
            <Chip
              key={index.toString()}
              label={farm.label}
              onDismiss={() => {
                onChange({
                  ...filters,
                  farm: filters.farm
                    .filter((newFarm) => newFarm.id !== farm.id),
                });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

Filters.defaultProps = {
  filters: {},
  data: [],
};

Filters.propTypes = {
  filters: PropTypes.shape({
    district: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })),
    region: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })),
    harvest: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    state: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })),
    city: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })),
    owner: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })),
    identity: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })),
    farm: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })),
  }),
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    farm: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    owner: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      identity: PropTypes.string.isRequired,
    }).isRequired,
    state: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    city: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    district: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    region: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  })),
  onChange: PropTypes.func.isRequired,
};

export default Filters;
