import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  emptyContaier: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'italic',
  },
});

export default useStyles;
