import { makeStyles } from '@material-ui/styles';

import logoKoogle from '../../../../assets/green-logo-koogle.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  topContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  logoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 25,
  },
  logoFooterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  koogleLogoContainer: {
    backgroundImage: `url(${logoKoogle})`,
    height: 129,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flex: 1,
    backgroundSize: 'contain',
    marginLeft: '25%',
    marginRight: '25%',
  },
  bar: {
    backgroundColor: '#6f6f6f',
    width: 1,
    height: '30%',
    display: 'flex',
    marginLeft: 25,
    marginRight: 25,
  },
  space: {
    height: 15,
  },
});

export default useStyles;
