import React from 'react';
import classnames from 'classnames';

import { Input } from '../../../../components';
import useStyles from './styles';

const SearchBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <Input label="Produtor" />
      </div>
      <div className={classes.inputContainer}>
        <Input label="CPF/CNPJ" />
      </div>
      <div className={classes.inputContainer}>
        <Input label="Fazenda" />
      </div>
      <div className={classes.inputContainer}>
        <Input label="Cultura" />
      </div>
      <div className={classes.inputContainer}>
        <Input label="Safra" />
      </div>
      <div className={classes.inputContainer}>
        <Input label="Cidade" />
      </div>
      <div className={classnames(classes.inputContainer, classes.miniInputContainer)}>
        <Input label="UF" />
      </div>
    </div>
  );
};

export default SearchBar;
