import { get } from 'lodash';
import {
  getBounds, getCenterOfBounds, getDistance,
} from 'geolib';

import { GEOJSON_LINE, GEOJSON_POLYGON } from '../constants';
import { divide } from './math';

const types = {
  LineString: GEOJSON_LINE,
  Polygon: GEOJSON_POLYGON,
};

export const handleGeoJsonToShape = (geoJSON) => {
  const polygonsGeometry = get(geoJSON, 'geometries', []).map(({ type: shapeType, coordinates }) => {
    const type = get(types, shapeType, false);

    return ({
      type,
      geometry: type === GEOJSON_POLYGON ? coordinates[0] : coordinates,
    });
  }).filter(({ type }) => type);

  return {
    polygons: polygonsGeometry
      .filter(({ type }) => type === GEOJSON_POLYGON)
      .map(({ geometry }) => geometry.map(([lng, lat]) => ({
        lat, lng,
      }))),
    lines: polygonsGeometry
      .filter(({ type }) => type === GEOJSON_LINE)
      .map(({ geometry }) => geometry.map(([lng, lat]) => ({
        lat, lng,
      }))),
  };
};

export const getPolygonsCenter = (polygons) => {
  const center = getCenterOfBounds(
    polygons.map(({ lat, lng }) => ({
      latitude: lat,
      longitude: lng,
    })),
  );

  return center;
};

export const getPolygonsFence = (polygons) => {
  if (!polygons.length) {
    return null;
  }

  const mergedPolygons = polygons.reduce((previous, current) => [
    ...previous,
    ...current,
  ], []);

  const center = getCenterOfBounds(mergedPolygons);

  const {
    minLat, maxLat, minLng, maxLng,
  } = getBounds(mergedPolygons);

  const radius = divide(getDistance({
    latitude: minLat,
    longitude: maxLng,
  }, {
    latitude: maxLat,
    longitude: minLng,
  }), 2);

  return {
    center: {
      lat: center.latitude,
      lng: center.longitude,
    },
    radius,
  };
};

export const getGeoJsonsCenter = (geoJSON) => {
  const geoJsonsCenter = geoJSON.map((data) => {
    const { polygons } = handleGeoJsonToShape(data);

    const polygonsCenter = polygons.map((polygon) => getCenterOfBounds(
      polygon.map(({ lat, lng }) => ({
        latitude: lat,
        longitude: lng,
      })),
    ));

    if (polygonsCenter.length) {
      return getCenterOfBounds(polygonsCenter);
    }

    return false;
  }).filter((center) => center);

  if (geoJsonsCenter.length) {
    return getCenterOfBounds(geoJsonsCenter);
  }

  return false;
};
