// ROUTES
const KOOGLE_BASE_ROUTE = '/koogle';

// PAGINATION
const ITEMS_PER_PAGE = 30;

// FORMAT
const NUMERIC_FORMAT = 'NUMERIC_FORMAT';
const DECIMAL_SEPARATOR = ',';
const THOUSANDS_SEPARATOR = '.';
const CURRENCY_SYMBOL = 'R$';

// CHART
const AXIS_DIVISIONS = 5;

// TYPE REFERENCES
const REGION_REFERENCE = 'REGION_REFERENCE';
const DISTRICT_REFERENCE = 'DISTRICT_REFERENCE';
const OWNER_REFERENCE = 'OWNER_REFERENCE';
const FARM_REFERENCE = 'FARM_REFERENCE';

// GEOJSON TYPES
const GEOJSON_LINE = 'GEOJSON_LINE';
const GEOJSON_POLYGON = 'GEOJSON_POLYGON';
const GEOJSON_POINT = 'GEOJSON_POINT';

export {
  KOOGLE_BASE_ROUTE,
  ITEMS_PER_PAGE,
  NUMERIC_FORMAT,
  DECIMAL_SEPARATOR,
  THOUSANDS_SEPARATOR,
  CURRENCY_SYMBOL,
  AXIS_DIVISIONS,
  REGION_REFERENCE,
  DISTRICT_REFERENCE,
  OWNER_REFERENCE,
  FARM_REFERENCE,
  GEOJSON_LINE,
  GEOJSON_POLYGON,
  GEOJSON_POINT,
};
