import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { GoogleMap } from '@react-google-maps/api';
import PropTypes from 'prop-types';

import { get, isObject, orderBy } from 'lodash';
import useStyles from './styles';
import GeoJson from '../GeoJson';
import { getGeoJsonsCenter } from '../../helpers/map';
import { removeDuplicates } from '../../helpers/array';
import MapLegend from '../MapLegend';
import MapPin from '../MapPin';

const Map = ({
  style, initialCenter, zoom, geoJson: geoJsonsToRender,
  pin,
}) => {
  const classes = useStyles();
  const [mapCenterByGeoJson, setMapCenterByGeoJson] = useState(false);
  const [handledGeoJsons, setHandledGeoJsons] = useState([]);
  const mapInstance = useRef(null);
  const [legend, setLegend] = useState([]);
  const [legendFilter, setLegendFilter] = useState(null);

  const afterMapLoad = useCallback(() => {
    const legendItems = orderBy(
      removeDuplicates(handledGeoJsons.map(({ legend: geoJsonLegend }) => geoJsonLegend), { key: 'color' }),
      'label',
    );

    setLegend(legendItems);
  }, [handledGeoJsons]);

  const handleClickItem = useCallback((item) => {
    setLegendFilter((actualFilterItem) => {
      if (
        !actualFilterItem
        || get(actualFilterItem, 'color', '') !== item.color
      ) {
        return item;
      }

      return null;
    });
  }, []);

  useEffect(() => {
    let geoJsonToShow = [];

    if (isObject(geoJsonsToRender) && !Array.isArray(geoJsonsToRender)) {
      geoJsonToShow.push(geoJsonsToRender);
    } else if (Array.isArray(geoJsonsToRender)) {
      geoJsonToShow = [...geoJsonsToRender];
    }
    setHandledGeoJsons(geoJsonToShow);
    setMapCenterByGeoJson(getGeoJsonsCenter(geoJsonToShow.map(({ geoJson }) => geoJson)));
  }, [geoJsonsToRender]);

  useEffect(() => {
    if (mapInstance.current) {
      afterMapLoad(mapInstance.current);
    }
  }, [afterMapLoad, handledGeoJsons]);

  return (
    <>
      <div className={classes.root}>
        <GoogleMap
          onLoad={(map) => {
            mapInstance.current = map;
          }}
          mapContainerStyle={{
            display: 'flex',
            flex: 1,
            ...style,
          }}
          center={mapCenterByGeoJson ? {
            lat: get(mapCenterByGeoJson, 'latitude', 0),
            lng: get(mapCenterByGeoJson, 'longitude', 0),
          } : initialCenter}
          zoom={zoom}
          clickableIcons={false}
        >
          {
            pin.map((mapPin) => (
              <MapPin
                coords={mapPin.coords}
                data={mapPin.data}
              />
            ))
          }
          {
          handledGeoJsons.map(({
            geoJson: json, options, renderItem, legend: geoJsonLegend,
          }, index) => {
            const handledOptions = {
              ...options,
            };

            if (
              legendFilter && (
                geoJsonLegend.color !== get(legendFilter, 'color', '')
              || geoJsonLegend.label !== get(legendFilter, 'label', '')
              )) {
              handledOptions.fillOpacity = 0;
              handledOptions.strokeOpacity = 0;
            }

            return (
              <GeoJson
                key={index.toString()}
                data={json}
                options={handledOptions}
              >
                {renderItem}
              </GeoJson>
            );
          })
        }
        </GoogleMap>
      </div>
      <MapLegend
        filterSelected={legendFilter}
        items={legend}
        mapInstance={mapInstance.current}
        onClickItem={handleClickItem}
      />
    </>
  );
};

Map.defaultProps = {
  style: {},
  zoom: 10,
  geoJson: [],
  pin: [],
};

Map.propTypes = {
  style: PropTypes.shape({}),
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  zoom: PropTypes.number,
  geoJson: PropTypes.arrayOf(PropTypes.object),
  pin: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    data: PropTypes.shape({}),
  })),
};

export default Map;
