import React /* { useState } */ from 'react';
import {
  FlexibleXYPlot, AreaSeries, HorizontalGridLines,
  VerticalGridLines, XAxis, YAxis, DiscreteColorLegend,
  // Hint,
} from 'react-vis';
import PropTypes from 'prop-types';

import { chartAxisFormatter } from '../../helpers/format';
import useStyles from './styles';
import { messages } from '../../helpers/chart';
import { useGraphItemOpacity } from '../../hooks';
// import ChartHint from '../ChartHint';

const AreaChart = ({
  data, showLegend, legendStyle,
  // showHint
}) => {
  const classes = useStyles();
  // const [
  //   hoveredNode,
  //   setHoveredNode,
  // ] = useState(null);
  const { handleFocusedItemChange, getItemOpacity } = useGraphItemOpacity();

  return data.length ? (
    <FlexibleXYPlot
      xType="ordinal"
      margin={{ left: 50 }}
      // onMouseLeave={() => setHoveredNode(null)}
    >
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis />
      <YAxis
        tickFormat={chartAxisFormatter}
      />
      {showLegend && (
      <DiscreteColorLegend
        style={legendStyle}
        orientation="horizontal"
        items={data.map((item) => ({
          title: item.label,
        }))}
        onItemClick={handleFocusedItemChange}
      />
      )}
      {
        data.map((graph, index) => (
          <AreaSeries
            // onNearestXY={(d) => setHoveredNode(d)}
            key={index.toString()}
            curve="curveNatural"
            data={graph.values}
            opacity={getItemOpacity(graph.label)}
          />
        ))
      }
      {/* {hoveredNode && showHint && (
      <Hint
        xType={'ordinal'}
        yType={'literal'}
        value={{
          x: hoveredNode.x,
          y: hoveredNode.y,
        }}
      >
        <ChartHint>
          {hoveredNode.y}
        </ChartHint>
      </Hint>
      )} */}
    </FlexibleXYPlot>
  ) : (
    <div className={classes.emptyContaier}>
      {messages.EMPTY_DATA}
    </div>
  );
};

AreaChart.defaultProps = {
  data: [],
  showLegend: false,
  legendStyle: {},
};

AreaChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  })),
  showLegend: PropTypes.bool,
  legendStyle: PropTypes.shape({}),
};

export default AreaChart;
