import React from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Modal = ({
  isVisible, onClose, title, body, footer,
}) => {
  const classes = useStyles();

  return (
    <BootstrapModal
      show={isVisible}
      onHide={onClose}
      backdrop="static"
    >
      <BootstrapModal.Header
        closeButton
        bsPrefix={classes.modalHeader}
      >
        <BootstrapModal.Title
          bsPrefix={classes.modalTitle}
        >
          {title}
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {body}
      </BootstrapModal.Body>
      {footer && (
      <BootstrapModal.Footer>
        {footer}
      </BootstrapModal.Footer>
      )}
    </BootstrapModal>
  );
};

Modal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  title: '',
  body: null,
  footer: null,
};

Modal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
};

export default Modal;
