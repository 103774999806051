import React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';

import { makeRoute } from '../../helpers/route';
import useStyles from './styles';

const Link = ({ to, className, children }) => {
  const classes = useStyles();

  return (
    <MuiLink
      className={classnames(classes.container, className)}
      href={makeRoute(to.route, get(to, 'params', {}), get(to, 'search', {}))}
    >
      {children}
    </MuiLink>
  );
};

Link.defaultProps = {
  className: '',
};

Link.propTypes = {
  to: PropTypes.shape({
    route: PropTypes.string.isRequired,
    params: PropTypes.shape({}),
    search: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Link;
