import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.table.head,
  },
  th: {
    padding: '10px 0px',
    whiteSpace: 'nowrap',
  },
  thDiv: {
    padding: '0px 10px',
  },
  thBorder: {
    borderRight: '0.5px solid #C4BFBF',
  },
  td: {
    padding: 10,
    whiteSpace: 'nowrap',
  },
  line: {

  },
  evenLine: {
    backgroundColor: theme.palette.table.background1,
  },
  oddLine: {
    backgroundColor: theme.palette.table.background2,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  paginationContainer: {
    padding: '10px 0px',
  },
  emptyTd: {
    textAlign: 'center',
  },
  table: {
    minWidth: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  searchContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));

export default useStyles;
